import {
  addMinutes,
  differenceInMinutes,
  isSameDay,
  getHours,
  getMinutes,
} from "date-fns";
import { formatDate, getDateByTimeZone } from "@/lib/date";

export const DELIVERY_TYPE_NORMAL = "1";
export const DELIVERY_TYPE_PICKUP = "2";
export const DELIVERY_TYPE_RETURN = "3";

export const DELIVERY_STATUS_0 = "DELIVERY_STATUS_RECEIPT";
export const DELIVERY_STATUS_1 = "DELIVERY_STATUS_PICKUP_SCHEDULED";
export const DELIVERY_STATUS_2 = "DELIVERY_STATUS_PICKUP_COMPLETED";
export const DELIVERY_STATUS_3 = "DELIVERY_STATUS_WAREHOUSED";
export const DELIVERY_STATUS_4 = "DELIVERY_STATUS_DELIVERY_STARTED";
export const DELIVERY_STATUS_5 = "DELIVERY_STATUS_DELIVERED";
export const DELIVERY_STATUS_6 = "DELIVERY_STATUS_DELIVERY_SENT_BACK";
export const DELIVERY_STATUS_7 = "DELIVERY_STATUS_DELIVERY_LOST";
export const DELIVERY_STATUS_8 = "DELIVERY_RIDER_ALLOCATED";
export const DELIVERY_STATUS_9 = "ADDRESS_NOT_SUPPORTED";
export const DELIVERY_STATUS_10 = "ACCIDENT";
export const DELIVERY_STATUS_11 = "CANCELED";
export const DELIVERY_STATUS_12 = "DELIVERY_POSTPONED";
export const DELIVERY_STATUS_16 = "AGENCY_ALLOCATED"; // 배달 대행 관련
export const DELIVERY_STATUS_17 = "AGENCY_ALLOCATION_FAILED"; // 배달 대행 관련
export const DELIVERY_STATUS_18 = "AGENCY_CANCELED"; // 배달 대행 관련
export const DELIVERY_STATUS_19 = "AGENCY_DELIVERY_RIDER_ALLOCATED"; // 배달 대행 관련
export const DELIVERY_STATUS_20 = "AGENCY_DELIVERY_RIDER_ALLOCATION_CANCELED"; // 배달 대행 관련

export const getPointToArray = (point) => {
  if (point && point.indexOf("POINT") > -1) {
    let pointArray = point.substring(6, point.length - 1).split(" ");

    return pointArray;
  } else {
    return [0, 0];
  }
};

export const renderStatusByNumber = (status) => {
  switch (status) {
    case 0:
      return "접수";
    case 1:
      return "수거지정";
    case 2:
      return "수거완료";
    case 3:
      return "입고완료";
    case 4:
      return "배송출발";
    case 5:
      return "배송완료";
    case 6:
      return "반송완료";
    case 7:
      return "분실완료";
    case 8:
      return "배송배차";
    case 9:
      return "배송불가";
    case 10:
      return "사고";
    case 11:
      return "취소";
    case 12:
      return "배송연기";
    case 16:
      return "배달대행 접수";
    case 17:
      return "배달대행 접수 실패";
    case 18:
      return "배달대행에서 취소";
    case 19:
      return "배달대행에서 라이더 배차";
    case 20:
      return "배달대행에서 라이더 배차 취소";
    default:
      return "";
  }
};

export class Delivery {
  accidentAt = null;
  accidentReason = "";
  agencyCorpUser = null;
  agencyCorpUserId = null;
  bookId = "";
  bookedAt = null;
  canceledAt = null;
  canceledReason = "";
  deliveryAllocatedDate = null;
  deliveryCompletedDate = null;
  deliveryRider = null;
  deliveryStatusLogs = [];
  delayedDeliveries = [];
  etc1 = "";
  etc2 = "";
  etc3 = "";
  etc4 = "{}";
  manualCompletedDeliveryReason = "";
  notReceivedImageLocationOld = "";
  order = null;
  orderIdFromCorp = "";
  prepaid = null;
  pickupDateCompleted = null;
  pickupDateScheduled = null;
  pickupRider = null;
  postponedDate = null;
  productPrice = 0;
  productName = "";
  reallocatedRider = null;
  reallocationRequestedAt = null;
  receiptDate = null;
  receiverAddress = null;
  receiverAddressRoad = null;
  receiverAllocationGroupNameOnWeekdays = null;
  receiverAllocationGroupNameOnWeekends = null;
  receiverDong = null;
  receiverSigungu = null;
  receiverSido = null;
  receiverMobile = "";
  receiverMobile2 = "";
  receiverPoint = null;
  receiverPostalCode = null;
  receiverName = null;
  releasedAt = null;
  senderAllocationGroupNameOnWeekdays = null;
  senderAllocationGroupNameOnWeekends = null;
  senderDong = null;
  senderMobile = "";
  senderMobile2 = "";
  senderName = null;
  senderPostalCode = null;
  senderPoint = null;
  senderAddress = null;
  senderAddressDetail = null;
  senderAddressRoad = null;
  sentBackLogs = null;
  sentBackDate = null;
  signImageLocationOld = "";
  spot = null;
  status = DELIVERY_STATUS_0;
  time = null;
  warehousedAt = null;
  type = DELIVERY_TYPE_NORMAL;
  unrefinedAddress = null;

  // canceled
  isAccident = false;
  isCanceled = false;

  _lostDate = null;

  constructor(delivery) {
    this.bookId = delivery.bookId;
    switch (Number(delivery.status)) {
      case 0:
        this.status = DELIVERY_STATUS_0;
        break;
      case 1:
        this.status = DELIVERY_STATUS_1;
        break;
      case 2:
        this.status = DELIVERY_STATUS_2;
        break;
      case 3:
        this.status = DELIVERY_STATUS_3;
        break;
      case 4:
        this.status = DELIVERY_STATUS_4;
        break;
      case 5:
        this.status = DELIVERY_STATUS_5;
        break;
      case 6:
        this.status = DELIVERY_STATUS_6;
        break;
      case 7:
        this.status = DELIVERY_STATUS_7;
        break;
      case 8:
        this.status = DELIVERY_STATUS_8;
        break;
      case 9:
        this.status = DELIVERY_STATUS_9;
        break;
      case 10:
        this.status = DELIVERY_STATUS_10;
        break;
      case 11:
        this.status = DELIVERY_STATUS_11;
        break;
      case 12:
        this.status = DELIVERY_STATUS_12;
        break;
      case 16:
        this.status = DELIVERY_STATUS_16;
        break;
      case 17:
        this.status = DELIVERY_STATUS_17;
        break;
      case 18:
        this.status = DELIVERY_STATUS_18;
        break;
      case 19:
        this.status = DELIVERY_STATUS_19;
        break;
      case 20:
        this.status = DELIVERY_STATUS_20;
        break;
      default:
        this.status = "";
        break;
      // throw new Error("잘못된 배송상태값입니다.");
    }

    // dates by status
    this.bookedAt = delivery.bookedAt;
    this.agencyCorpUser = delivery.agencyCorpUser;
    this.agencyCorpUserId =
      delivery.agencyCorpUserId || delivery.agencyCorpUser?.id;
    this.deliveryAllocatedDate = delivery.deliveryAllocatedDate;
    this.deliveryCompletedDate = delivery.deliveryCompletedDate;
    this.deliveryRider = delivery.deliveryRider;
    this.deliveryStatusLogs = delivery.deliveryStatusLogs;
    this.delayedDeliveries = delivery.delayedDeliveries;
    this.etc1 = delivery.etc1;
    this.etc2 = delivery.etc2;
    this.etc3 = delivery.etc3;
    this.etc4 = delivery.etc4;
    this.manualCompletedDeliveryReason = delivery.manualCompletedDeliveryReason;
    this.memoFromCustomer = delivery.memoFromCustomer;
    this.notReceivedImageLocationOld = delivery.notReceivedImageLocation;
    this.order = delivery.order;
    this.orderIdFromCorp = delivery.orderIdFromCorp;
    this.postponedDate = delivery.postponedDate;
    this.prepaid = delivery.prepaid;
    this.pickupDateCompleted = delivery.pickupDateCompleted;
    this.pickupRider = delivery.pickupRider;
    this.pickupDateScheduled = delivery.pickupDateScheduled;
    this.reallocationRequestedAt = delivery.reallocationRequestedAt;
    this.reallocatedRider = delivery.reallocatedRider;
    this.receiptDate = delivery.receiptDate;
    this.receiverAddress = delivery.receiverAddress;
    this.receiverAddressRoad = delivery.receiverAddressRoad;
    this.receiverAddressDetail = delivery.receiverAddressDetail;
    this.receiverAllocationGroupNameOnWeekdays =
      delivery.receiverAllocationGroupNameOnWeekdays;
    this.receiverAllocationGroupNameOnWeekends =
      delivery.receiverAllocationGroupNameOnWeekends;
    this.receiverDong = delivery.receiverDongObj;
    this.receiverMobile = delivery.receiverMobile;
    this.receiverMobile2 = delivery.receiverMobile2;
    this.receiverName = delivery.receiverName;
    this.receiverPoint = delivery.receiverPoint;
    this.receiverPostalCode = delivery.receiverPostalCode;
    this.receiverSigungu =
      (delivery.receiverDongObj && delivery.receiverDongObj.sigungu) || null;
    this.receiverSido =
      (this.receiverSigungu && this.receiverSigungu.sido) || null;
    this.releasedAt = delivery.releasedAt;
    this.productPrice = delivery.productPrice;
    this.productName = delivery.productName;
    this.senderAddress = delivery.senderAddress;
    this.senderAddressDetail = delivery.senderAddressDetail;
    this.senderAddressRoad = delivery.senderAddressRoad;
    this.senderAllocationGroupNameOnWeekdays =
      delivery.senderAllocationGroupNameOnWeekdays;
    this.senderAllocationGroupNameOnWeekends =
      delivery.senderAllocationGroupNameOnWeekends;
    this.senderDong = delivery.senderDongObj;
    this.senderMobile = delivery.senderMobile;
    this.senderMobile2 = delivery.senderMobile2;
    this.senderName = delivery.senderName;
    this.senderPostalCode = delivery.senderPostalCode;
    this.senderPoint = delivery.senderPoint;
    this.signImageLocationOld = delivery.signImageLocation;
    this.sentBackLogs = delivery.sentBackLogs;
    this.sentBackDate = delivery.sentBackDate;
    this.spot = delivery.spot || null;
    this.time = delivery.time || null;
    this.type = delivery.type || DELIVERY_TYPE_NORMAL;
    this.warehousedAt = delivery.warehousedAt;

    // canceled
    this.isAccident = !!delivery.isAccident;
    this.accidentAt = delivery.accidentAt || null;
    this.accidentReason = delivery.accidentReason || null;
    this.isCanceled = !!delivery.isCanceled;
    this.canceledAt = delivery.canceledAt || null;
    this.canceledReason = delivery.canceledReason || null;

    this.unrefinedAddress = delivery.unrefinedAddress;

    this._lostDate = delivery._lostDate;
  }

  get addressApiType() {
    return this.etc4Obj ? this.etc4Obj.addressApiType : "";
  }

  get agencyNotReceivedImageLocation() {
    return this.etc4Obj ? this.etc4Obj.agencyNotReceivedImageLocation : "";
  }

  get agencyDeliveryRiderName() {
    return this.etc4Obj ? this.etc4Obj.agencyDeliveryRiderName : "";
  }

  get agencyDeliveryRiderMobile() {
    return this.etc4Obj ? this.etc4Obj.agencyDeliveryRiderMobile : "";
  }

  get agencyOrderId() {
    return this.etc4Obj ? this.etc4Obj.agencyOrderId : "";
  }

  get completedLocation() {
    return this.etc4Obj ? this.etc4Obj.completedLocation : "";
  }

  get customerAddress() {
    return this.etc4Obj ? this.etc4Obj.customerAddress : "";
  }

  get customerAddressDetail() {
    return this.etc4Obj ? this.etc4Obj.customerAddressDetail : "";
  }

  get customerAddressPostalCode() {
    return this.etc4Obj ? this.etc4Obj.customerAddressPostalCode : "";
  }

  get deliveryDoneLatitude() {
    return this.etc4Obj ? this.etc4Obj.deliveryDoneLatitude : "";
  }

  get deliveryDoneLongitude() {
    return this.etc4Obj ? this.etc4Obj.deliveryDoneLongitude : "";
  }

  get deliveryLostLatitude() {
    return this.etc4Obj ? this.etc4Obj.deliveryLostLatitude : "";
  }

  get deliveryLostLongitude() {
    return this.etc4Obj ? this.etc4Obj.deliveryLostLongitude : "";
  }

  get deliveryPlace() {
    return this.etc4Obj ? this.etc4Obj.deliveryPlace : "";
  }

  get deliveryPlacedTime() {
    return this.etc4Obj ? this.etc4Obj.deliveryPlacedTime : "";
  }

  get deliveryRiderName() {
    return this.deliveryRider ? this.deliveryRider.name : "";
  }

  get deliverySentBackLatitude() {
    return this.etc4Obj ? this.etc4Obj.deliverySentBackLatitude : "";
  }

  get deliverySentBackLongitude() {
    return this.etc4Obj ? this.etc4Obj.deliverySentBackLongitude : "";
  }

  get etc4Obj() {
    try {
      return this.etc4 && JSON.parse(this.etc4);
    } catch (e) {
      return {};
    }
  }

  get frontdoorPassword() {
    if (this.frontdoorPasswordByClient) {
      return `${this.frontdoorPasswordByClient} ${
        this.frontdoorPasswordByCorp ? `/ ${this.frontdoorPasswordByCorp}` : ""
      }`;
    } else {
      return this.frontdoorPasswordByCorp || null;
    }
  }

  get frontdoorPasswordByClient() {
    return this.etc4Obj ? this.etc4Obj.frontdoorPasswordByClient : "";
  }

  get frontdoorPasswordByCorp() {
    return this.etc4Obj ? this.etc4Obj.frontdoorPasswordByCorp : "";
  }

  get lostReason() {
    return this.etc4Obj && this.etc4Obj.lostReason
      ? this.etc4Obj.lostReason
      : "";
  }

  get notReceivedImageLocation() {
    return this.etc4Obj && this.etc4Obj.notReceivedImageLocation
      ? this.etc4Obj.notReceivedImageLocation
      : this.notReceivedImageLocationOld;
  }

  get orderingNumberByDeliveryRiderId() {
    return this.etc4Obj?.orderingNumberByDeliveryRiderId || "";
  }

  get pickupRiderName() {
    return this.pickupRider ? this.pickupRider.name : "";
  }

  get pickupCompletedImage() {
    return this.etc4Obj?.pickupCompletedImage || null;
  }

  get reallocatedRiderName() {
    return this.reallocatedRider ? this.reallocatedRider.name : "";
  }

  get prepaidText() {
    return this.prepaid ? "선불" : "후불";
  }

  get receiverAddressObj() {
    return this.etc4Obj ? this.etc4Obj.receiverAddressObj : null;
  }

  get receiverAddressPostalCode() {
    return this.receiverAddressObj ? this.receiverAddressObj.postalCode : null;
  }

  get receiverAddressRoadWithoutSigungu() {
    if (this.receiverAddressRoad) {
      let receiverAddressRoadArr = this.receiverAddressRoad.split(" ");

      if (receiverAddressRoadArr.length > 2) {
        return receiverAddressRoadArr
          .slice(2, receiverAddressRoadArr.length)
          .join(" ");
      } else {
        return this.receiverAddressRoad;
      }
    } else {
      return "";
    }
  }

  get receiverBuildingDongNumber() {
    if (this.receiverAddressObj && this.receiverAddressObj.buildingDongNumber) {
      return this.receiverAddressObj.buildingDongNumber;
    } else {
      return null;
    }
  }

  get receiverDongName() {
    return this.receiverDong ? this.receiverDong.name : "";
  }

  get receiverSigunguName() {
    return this.receiverSigungu ? this.receiverSigungu.name : "";
  }

  get receiverSidoName() {
    return this.receiverSido ? this.receiverSido.name : "";
  }

  get receiverLnglat() {
    return {
      longitude: getPointToArray(this.receiverPoint)[0],
      latitude: getPointToArray(this.receiverPoint)[1],
    };
  }

  get renderAddressApiType() {
    if (this.addressApiType === "TMAP") {
      return "서브1 정제:기본정제실패";
    } else if (this.addressApiType === "KAKAO") {
      return "서브2 정제:기본정제실패";
    } else if (this.addressApiType === "SOOJIONE") {
      if (this.soojioneLimitedCustomized) {
        return "입력 주소값 일치 정제";
      } else {
        return "건물명 매칭 조정정제";
      }
    } else {
      return "";
    }
  }

  get renderManualCompletedDeliveryReason() {
    return (
      this.etc4Obj?.manualCompletedDeliveryReason ||
      this.manualCompletedDeliveryReason
    );
  }

  get sendedCompletedSms() {
    return this.etc4Obj ? this.etc4Obj.sendedCompletedSms : false;
  }

  get senderAddressObj() {
    if (this.etc4Obj && this.etc4Obj.senderAddressObj) {
      if (typeof this.etc4Obj.senderAddressObj === "string") {
        return JSON.parse(this.etc4Obj.senderAddressObj);
      } else if (typeof this.etc4Obj.senderAddressObj === "object") {
        return this.etc4Obj.senderAddressObj;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  get senderAddressPostalCode() {
    return this.senderAddressObj ? this.senderAddressObj.postalCode : "";
  }

  get senderDongName() {
    return this.senderDong && this.senderDong.name;
  }

  get senderLnglat() {
    return {
      longitude: getPointToArray(this.senderPoint)[0],
      latitude: getPointToArray(this.senderPoint)[1],
    };
  }

  get sentBackReason() {
    return this.sentBackLogs && this.sentBackLogs.length > 0
      ? this.sentBackLogs[0].reason
      : "";
  }

  get signImageLocation() {
    return this.etc4Obj && this.etc4Obj.signImageLocation
      ? this.etc4Obj.signImageLocation
      : this.signImageLocationOld;
  }

  get soojioneLimitedCustomized() {
    return this.etc4Obj ? this.etc4Obj.soojioneLimitedCustomized : null;
  }

  get spotName() {
    return this.spot && this.spot.name;
  }

  get isPossibleToRestoreAccident() {
    return !!this.isAccident;
  }

  get isPossibleToRestoreCancel() {
    return !!this.isCanceled;
  }

  get isPossibleToKookmin() {
    return (
      !this.isAccident &&
      !this.isCanceled &&
      this.status === DELIVERY_STATUS_4 &&
      this.type === DELIVERY_TYPE_NORMAL
    );
  }

  get isPossibleToCancelKookmin() {
    return !!this.agencyCorpUserId && this.status !== DELIVERY_STATUS_17;
  }

  get isPossibleToSetDeliveryCompletedStatus() {
    return (
      !this.isAccident && !this.isCanceled && this.status === DELIVERY_STATUS_4
    );
  }

  get isPossibleToSetPreviousStatus() {
    return !this.isAccident && !this.isCanceled;
  }

  get isPossibleToSetDeliveryStarted() {
    return (
      !this.isAccident &&
      !this.isCanceled &&
      (this.status === DELIVERY_STATUS_3 ||
        this.status === DELIVERY_STATUS_8) &&
      this.deliveryRider &&
      !this.reallocatedRider
    );
  }

  get isPossibleToSetWarehousedStatus() {
    return (
      !this.isAccident && !this.isCanceled && this.status === DELIVERY_STATUS_2
    );
  }

  get isPossibleToSetWarehousedStatusBeforePickup() {
    return (
      !this.isAccident && !this.isCanceled && this.status === DELIVERY_STATUS_1
    );
  }

  get isPossibleToUpdateKookmin() {
    return !!this.agencyCorpUserId;
  }

  get updateDeliveryCompletedStateByAdmin() {
    return this.etc4Obj && this.etc4Obj.updateDeliveryCompletedStateByAdmin;
  }

  get updateDeliveryCompletedStateBy() {
    return this.etc4Obj && this.etc4Obj.updateDeliveryCompletedStateBy;
  }

  get updateDeliveryCompletedStateAt() {
    return this.etc4Obj && this.etc4Obj.updateDeliveryCompletedStateAt;
  }

  renderAccidentAt(dateFormat = "MM/dd HH:mm") {
    return (
      this.accidentAt &&
      formatDate(getDateByTimeZone(this.accidentAt), dateFormat)
    );
  }

  renderBookId() {
    if (this.status === DELIVERY_STATUS_17) {
      return `(실)${this.bookId}`;
    } else if (this.agencyCorpUserId) {
      return `(배)${this.bookId}`;
    } else if (this.status === DELIVERY_STATUS_7) {
      return `(분)${this.bookId}`;
    } else if (this.status === DELIVERY_STATUS_6) {
      return `(반)${this.bookId}`;
    } else if (this.type === DELIVERY_TYPE_PICKUP) {
      return `(픽)${this.bookId}`;
    } else {
      return this.bookId;
    }
  }

  renderCanceledAt(dateFormat = "MM/dd HH:mm") {
    return (
      this.canceledAt &&
      formatDate(getDateByTimeZone(this.canceledAt), dateFormat)
    );
  }

  renderDeliveryCompletedDate(dateFormat = "MM/dd HH:mm") {
    if (this.status === DELIVERY_STATUS_7) {
      return (
        this._lostDate &&
        `${formatDate(getDateByTimeZone(this._lostDate), dateFormat)}(분)`
      );
      // return (
      //   this.renderDeliveryStatusLogsCreatedAt(7) &&
      //   `${formatDate(
      //     getDateByTimeZone(this.renderDeliveryStatusLogsCreatedAt(7)),
      //     dateFormat,
      //   )}(분)`
      // );
    } else if (this.status === DELIVERY_STATUS_6) {
      return (
        this.sentBackDate &&
        `${formatDate(getDateByTimeZone(this.sentBackDate), dateFormat)}(반)`
      );
      // return (
      //   this.renderDeliveryStatusLogsCreatedAt(6) &&
      //   `${formatDate(
      //     getDateByTimeZone(this.renderDeliveryStatusLogsCreatedAt(6)),
      //     dateFormat,
      //   )}(반)`
      // );
    } else {
      return (
        this.deliveryCompletedDate &&
        formatDate(getDateByTimeZone(this.deliveryCompletedDate), dateFormat)
      );
    }
  }

  // renderDeliveryLostDate(dateFormat = "MM/dd HH:mm") {
  //   return (
  //     this.renderDeliveryStatusLogsCreatedAt(7) &&
  //     formatDate(
  //       getDateByTimeZone(this.renderDeliveryStatusLogsCreatedAt(7)),
  //       dateFormat,
  //     )
  //   );
  // }

  renderDeliveryLostDate(dateFormat = "MM/dd HH:mm") {
    return (
      this._lostDate &&
      formatDate(getDateByTimeZone(this._lostDate), dateFormat)
    );
  }

  // renderDeliverySentBackDate(dateFormat = "MM/dd HH:mm") {
  //   return (
  //     this.renderDeliveryStatusLogsCreatedAt(6) &&
  //     formatDate(
  //       getDateByTimeZone(this.renderDeliveryStatusLogsCreatedAt(6)),
  //       dateFormat,
  //     )
  //   );
  // }

  // renderDeliveryStatusLogsCreatedAt(after = 7) {
  //   const deliveryStatusLog =
  //     this.deliveryStatusLogs &&
  //     this.deliveryStatusLogs.find((el) => el.after === after);

  //   if (deliveryStatusLog) {
  //     return deliveryStatusLog.createdAt;
  //   } else {
  //     return null;
  //   }
  // }

  renderPickupDateCompleted(dateFormat = "MM/dd HH:mm") {
    return (
      this.pickupDateCompleted &&
      formatDate(getDateByTimeZone(this.pickupDateCompleted), dateFormat)
    );
  }

  renderPickupDateScheduled(dateFormat = "MM/dd HH:mm") {
    return (
      this.pickupDateScheduled &&
      formatDate(getDateByTimeZone(this.pickupDateScheduled), dateFormat)
    );
  }

  renderReallocationRequestedAt(dateFormat = "MM/dd HH:mm") {
    return (
      this.reallocationRequestedAt &&
      formatDate(getDateByTimeZone(this.reallocationRequestedAt), dateFormat)
    );
  }

  renderReceiptDate(dateFormat = "MM/dd HH:mm") {
    return (
      this.receiptDate &&
      formatDate(getDateByTimeZone(this.receiptDate), dateFormat)
    );
  }

  renderDeliveryAllocatedDate(dateFormat = "MM/dd HH:mm") {
    return (
      this.deliveryAllocatedDate &&
      formatDate(getDateByTimeZone(this.deliveryAllocatedDate), dateFormat)
    );
  }

  renderPostponedDate(dateFormat = "MM/dd HH:mm") {
    return (
      this.postponedDate &&
      formatDate(getDateByTimeZone(this.postponedDate), dateFormat)
    );
  }

  renderReleasedAt(dateFormat = "MM/dd HH:mm") {
    return (
      this.releasedAt &&
      formatDate(getDateByTimeZone(this.releasedAt), dateFormat)
    );
  }

  renderSentBackDate(dateFormat = "MM/dd HH:mm") {
    return (
      this.sentBackDate &&
      formatDate(getDateByTimeZone(this.sentBackDate), dateFormat)
    );
  }

  renderEstimatedTime() {
    if (this.status === DELIVERY_STATUS_4 && this.order) {
      const current = new Date();

      const from = addMinutes(new Date(), this.order * 10);
      const to = addMinutes(new Date(), this.order * 10 + 120);

      let displayFrom = formatDate(from, "H:m");
      if (
        !isSameDay(current, from) ||
        (getHours(from) >= 22 && getMinutes(from) >= 30)
      ) {
        displayFrom = "22:30";
      }

      let displayTo = formatDate(to, "H:m");
      if (
        !isSameDay(current, to) ||
        (getHours(to) >= 22 && getMinutes(to) >= 30)
      ) {
        displayTo = "22:30";
      }

      return `${displayFrom} ~ ${displayTo}(${this.order})`;
    } else {
      return "";
    }
  }

  renderStatus() {
    switch (this.status) {
      case DELIVERY_STATUS_1:
        return "수거지정";
      case DELIVERY_STATUS_2:
        return "수거완료";
      case DELIVERY_STATUS_3:
        return "입고완료";
      case DELIVERY_STATUS_4:
        return "배송출발";
      case DELIVERY_STATUS_5:
        return "배송완료";
      case DELIVERY_STATUS_6:
        return "반송완료";
      case DELIVERY_STATUS_7:
        return "분실완료";
      case DELIVERY_STATUS_8:
        return "배송배차";
      case DELIVERY_STATUS_9:
        return "배송불가";
      case DELIVERY_STATUS_10:
        return "사고";
      case DELIVERY_STATUS_11:
        return "취소";
      case DELIVERY_STATUS_12:
        return "배송연기";
      default:
        return "접수";
    }
  }

  renderTime() {
    let total = 0;

    if (this.releasedAt && this.deliveryCompletedDate) {
      total = differenceInMinutes(
        new Date(this.deliveryCompletedDate),
        new Date(this.releasedAt),
      );
    } else {
      return "";
    }

    const time = parseInt(total / 60);
    const minute = total % 60;

    return `${time < 10 ? "0" : ""}${time}:${minute < 10 ? "0" : ""}${minute}`;
  }

  renderWarehousedAt(dateFormat = "MM/dd HH:mm") {
    return (
      this.warehousedAt &&
      formatDate(getDateByTimeZone(this.warehousedAt), dateFormat)
    );
  }
}
