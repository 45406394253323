import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import dfSubDays from "date-fns/subDays";
import { formatDate, getDateByTimeZone } from "@/lib/date";
import { Delivery } from "@/Models";
import { useApiStore } from "@/store/hooks";

import {
  QUERY_DATE_TYPE_RECEIPT_DATE,
  QUERY_DELIVERY_TYPE_ALL,
  QUERY_DELIVERY_TYPE_TYPE_PICKUP,
  QUERY_DELIVERY_TYPE_TYPE_RETURN,
  QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN,
  AGENCY_KOOKMIN_CORPTITLE,
} from "@/store/Deliveries";

export const CONSTANTS = {
  SET_COUNT: "SET_COUNT",
  SET_QUERY: "SET_QUERY",
  FETCH_ALL: "FETCH_ALL",
  FETCH_ALL_DELAYED: "FETCH_ALL_DELAYED",
  RESET: "RESET",
  RESET_QUERY: "RESET_QUERY",
};

const zonedDateYesterday = dfSubDays(getDateByTimeZone(), 1);
const zonedDateToday = getDateByTimeZone();
const initDateFrom = formatDate(zonedDateYesterday);
const initDateTo = formatDate(zonedDateToday);

const INITIAL_QUERY = {
  bookId: "",
  corpUserName:
    process.env.REACT_APP_ENV === "production" ? "마켓컬리즉시배송" : "컬리",
  dateType: QUERY_DATE_TYPE_RECEIPT_DATE,
  dateFrom: initDateFrom,
  dateTo: initDateTo,
  orderBy: "",
  orderIdFromCorp: "",
  page: 1,
  pageSize: 100,
  receiverAddressRoad: "",
  receiverDong: "",
  receiverMobile: "",
  receiverName: "",
  region: "",
  rider: "",
  pickupRiderName: "",
  deliveryRiderName: "",
  senderDong: "",
  spotName: "",
  senderName: "",
  senderMobile: "",
  queryTypes: [QUERY_DELIVERY_TYPE_ALL],
  types: "1;2;3",
};

const INITIAL_STATE = {
  count: {
    all: 0,
    receipted: 0,
    pickupScheduled: 0,
    pickupCompleted: 0,
    warehoused: 0,
    deliveryAllocated: 0,
    postponed: 0,
    deliveryStarted: 0,
    deliveryCompleted: 0,
    accident: 0,
    canceled: 0,
    typePickup: 0,
    typeReturn: 0,
    senderAddressError: 0,
    senderAddressErrorCanceled: 0,
    receiverAddressError: 0,
    receiverAddressErrorCanceled: 0,
    deliverySentBack: 0,
    deliveryLost: 0,
    mobileError: 0,
    delayed: 0,
    addressNotSupported: 0,
    addressNotSupportedCanceled: 0,
    agencyAllocationKookmin: 0,
    agencyAllocationFailed: 0,
  },
  deliveries: [],
  deliveriesWholeData: [],
  pageCount: 1,
  query: INITIAL_QUERY,
  reset: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        deliveriesWholeData: action.deliveriesWholeData,
        deliveries: action.deliveries,
        pageCount: action.pageCount,
      };
    case CONSTANTS.FETCH_ALL_DELAYED:
      return {
        ...state,
        deliveries: action.deliveries,
      };
    case CONSTANTS.RESET:
      return {
        ...state,
        reset: action.reset,
      };
    case CONSTANTS.RESET_QUERY:
      return {
        ...state,
        query: INITIAL_QUERY,
        reset: true,
      };
    case CONSTANTS.SET_COUNT:
      return {
        ...state,
        count: {
          ...action.count,
          delayed: action.countDelayed,
          all:
            action.count.receipted +
            action.count.pickupScheduled +
            action.count.pickupCompleted +
            action.count.warehoused +
            action.count.deliveryAllocated +
            action.count.postponed +
            action.count.deliveryStarted +
            action.count.deliveryCompleted,
        },
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

const makeQueryStringObj = (query = INITIAL_QUERY) => {
  const queryTypes =
    query.queryTypes.includes(QUERY_DELIVERY_TYPE_ALL) ||
    query.queryTypes.includes(QUERY_DELIVERY_TYPE_TYPE_PICKUP) ||
    query.queryTypes.includes(QUERY_DELIVERY_TYPE_TYPE_RETURN)
      ? ""
      : query.queryTypes.join(";");

  let types = query.queryTypes.includes(QUERY_DELIVERY_TYPE_TYPE_PICKUP)
    ? "2"
    : query.queryTypes.includes(QUERY_DELIVERY_TYPE_TYPE_RETURN)
    ? "3"
    : query.types;

  let agencyCorpUserName = "";
  if (
    query.queryTypes.includes(QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN)
  ) {
    agencyCorpUserName = AGENCY_KOOKMIN_CORPTITLE;
  }

  return {
    ...query,
    queryTypes,
    types,
    agencyCorpUserName,
  };
};

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useDeliveriesKurlyStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const countByStatus = useCallback(
    async ({ query }) => {
      const _query = {
        ...makeQueryStringObj(query),
        riderId: query.rider ? query.rider.id : "",
      };

      Promise.allSettled([
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "receipted",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "pickupScheduled",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "pickupCompleted",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "warehoused",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "deliveryAllocated",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "deliveryStarted",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "deliveryCompleted",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "deliverySentBack",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "deliveryLost",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "accident",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "canceled",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          types: "2",
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          types: "3",
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "postponed",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "senderAddressError",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "senderAddressErrorCanceled",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "receiverAddressError",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "receiverAddressErrorCanceled",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "",
          agencyCorpUserName: AGENCY_KOOKMIN_CORPTITLE,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "agencyAllocationFailed",
          // agencyCorpUserName: AGENCY_KOOKMIN_CORPTITLE,
        }),
        api.get("/delayed-deliveries/list/by-rider-group-managers", {
          ...makeQueryStringObj(query),
          riderId: query.rider ? query.rider.id : "",
          types: query.types,
        }),
      ]).then((results) => {
        dispatch({
          type: CONSTANTS.SET_COUNT,
          count: {
            receipted:
              results[0]?.status === "rejected" ? null : results[0]?.value,
            pickupScheduled:
              results[1]?.status === "rejected" ? null : results[1]?.value,
            pickupCompleted:
              results[2]?.status === "rejected" ? null : results[2]?.value,
            warehoused:
              results[3]?.status === "rejected" ? null : results[3]?.value,
            deliveryAllocated:
              results[4]?.status === "rejected" ? null : results[4]?.value,
            deliveryStarted:
              results[5]?.status === "rejected" ? null : results[5]?.value,
            deliveryCompleted:
              results[6]?.status === "rejected" ? null : results[6]?.value,

            deliverySentBack:
              results[7]?.status === "rejected" ? null : results[7]?.value,
            deliveryLost:
              results[8]?.status === "rejected" ? null : results[8]?.value,
            accident:
              results[9]?.status === "rejected" ? null : results[9]?.value,
            canceled:
              results[10]?.status === "rejected" ? null : results[10]?.value,
            typePickup:
              results[11]?.status === "rejected" ? null : results[11]?.value,
            typeReturn:
              results[12]?.status === "rejected" ? null : results[12]?.value,
            postponed:
              results[13]?.status === "rejected" ? null : results[13]?.value,
            senderAddressError:
              results[14]?.status === "rejected" ? null : results[14]?.value,
            senderAddressErrorCanceled:
              results[15]?.status === "rejected" ? null : results[15]?.value,
            receiverAddressError:
              results[16]?.status === "rejected" ? null : results[16]?.value,
            receiverAddressErrorCanceled:
              results[17]?.status === "rejected" ? null : results[17]?.value,
            agencyAllocationKookmin:
              results[18]?.status === "rejected" ? null : results[18]?.value,
            agencyAllocationFailed:
              results[19]?.status === "rejected" ? null : results[19]?.value,
          },
          countDelayed:
            results[20]?.status === "rejected"
              ? null
              : results[20]?.value?.totalCount,
        });
      });

      return true;
    },
    [api.get, dispatch],
  );

  const fetchAll = useCallback(
    async ({ query }) => {
      const { deliveries, pageCount } = await api.get(
        "/deliveries/by-rider-group-managers",
        {
          ...makeQueryStringObj(query),
          riderId: query.rider ? query.rider.id : "",
        },
      );

      dispatch({
        type: CONSTANTS.FETCH_ALL,
        deliveries: deliveries.map((d) => new Delivery(d)),
        deliveriesWholeData: deliveries,
        pageCount,
      });

      return { deliveries, pageCount };
    },
    [api.get, dispatch],
  );

  const fetchAllDelayed = useCallback(
    async ({ query }) => {
      const deliveriesDelayed = await api.get(
        "/delayed-deliveries/list/by-rider-group-managers",
        {
          ...makeQueryStringObj(query),
          riderId: query.rider ? query.rider.id : "",
        },
      );

      dispatch({
        type: CONSTANTS.FETCH_ALL_DELAYED,
        deliveries: deliveriesDelayed.rows.map((d) => new Delivery(d)),
      });

      return { deliveriesDelayed };
    },
    [api.get, dispatch],
  );

  const fetchForDownload = useCallback(
    ({ query }) => {
      return api.get("/deliveries/by-rider-group-managers/download", {
        ...makeQueryStringObj(query),
        orderBy: query.orderBy,
        riderId: query.rider ? query.rider.id : "",
      });
    },
    [api.get],
  );

  const resetQuery = useCallback(() => {
    dispatch({
      type: CONSTANTS.RESET_QUERY,
    });
  }, [dispatch]);

  const reset = useCallback(
    (reset) => {
      dispatch({
        type: CONSTANTS.RESET,
        reset,
      });
    },
    [dispatch],
  );

  const setQuery = useCallback(
    (query = {}) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch],
  );

  return {
    state,
    countByStatus,
    fetchAll,
    fetchAllDelayed,
    fetchForDownload,
    reset,
    resetQuery,
    setQuery,
  };
};
