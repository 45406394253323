import { cloneElement, useCallback, useEffect, useRef } from "react";
import { createPortal } from 'react-dom';

import { SIZE_TYPE_200 } from "@/utils/Print";

export default function PrintPortal({ children, sizeType }) {
  const container = useRef(document.createElement("div"))
  let externalWindow = null;

  const labelMarginSize = () => {
    switch (sizeType) {
      case SIZE_TYPE_200:
        return 'margin-top: 5.5mm !important;';
      default:
        return 'margin-top: 2.2mm !important;';
    }
  }

  const labelSize = () => {
    switch (sizeType) {
      case SIZE_TYPE_200:
        return 'width: 200mm; height: 100mm;';
      default:
        return 'width: 100mm; height: 6in;';
    }
  }

  const pageSize = () => {
    switch (sizeType) {
      case SIZE_TYPE_200:
        return 'size: 200mm 100mm landscape;';
      default:
        return 'size: 100mm 6in;';
    }
  }

  const paperSize = () => {
    switch (sizeType) {
      case SIZE_TYPE_200:
        return 'print and (width: 100mm) and (height: 200mm) ';
      default:
        return '100mm 6in';
    }
  }

  useEffect(() => {
    externalWindow = window.open('', '', 'width=1200,height=1200,left=200,top=200');

    const css = `@media ${paperSize()} {
      @page { ${pageSize()} margin: 0; padding: 0; }
    }
  
    html, body { margin: 0; padding: 0; display: grid; }
      .label { ${labelSize()} position: relative; }
      .label:not(:first-of-type) { ${labelMarginSize()} }
    `;
  
    const head = externalWindow.document.head || document.getElementsByTagName('head')[0];
    const style = externalWindow.document.createElement('style');
    head.appendChild(style);
    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));

    externalWindow.document.body.appendChild(container.current);
    externalWindow.onafterprint = () => {
      setTimeout(() => {
        externalWindow.close();
      }, 200);
    };

    return () => {
      externalWindow.close();
    }
  }, []);

  const handleLoaded = useCallback(() => {
    externalWindow.print();
  }, [externalWindow]);

  return createPortal(
    cloneElement(children, {
      onLoaded: handleLoaded,
    }),
    container.current,
  );
};
