import React, { useState, useMemo, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import Table from "@/components/Table";
import { useRiderGroupsStore } from "@/store/hooks";

const GroupsTable = styled(Table)`
  th:nth-of-type(2) {
    width: 100px;
  }
`;

const GroupsTableRow = styled(Row)`
  padding: 10px 0;
`;

const Title = styled.h3``;

const SearchButtonColumn = styled(Col)`
  text-align: right;
`;

export default ({ onSelect }) => {
  const [nameQuery, setNameQuery] = useState("");
  const [selectedGroup1, setSelectedGroup1] = useState(null);
  const { state, ...riderGroupsActions } = useRiderGroupsStore();
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    riderGroupsActions.fetchRiderGroup1();
  }, []);

  function handleGoToPage(page) {
    riderGroupsActions.fetchRiderGroup1({
      name: nameQuery,
      page,
      pageSize: state.pageSize,
    });
  }

  function handleNextPage() {
    riderGroupsActions.fetchRiderGroup1({
      name: nameQuery,
      page: state.page + 1,
    });
  }

  function handlePreviousPage() {
    riderGroupsActions.fetchRiderGroup1({
      name: nameQuery,
      page: state.page - 1,
    });
  }

  function handleSetPageSize(pageSize) {
    riderGroupsActions.fetchRiderGroup1({ name: nameQuery, page: 1, pageSize });
  }

  function handleSelect() {
    onSelect(selectedGroup1);
  }

  function handleSelectGroup1({ selectedRows }) {
    setSelectedGroup1(selectedRows);
  }

  const onSubmit = async ({ name = "" }) => {
    setNameQuery(name);
    riderGroupsActions.fetchRiderGroup1({
      name,
      page: state.page,
      pageSize: state.pageSize,
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        width: 140,
      },
      {
        Header: "팀",
        accessor: (row) => (row.riderGroup2 ? row.riderGroup2.name : ""),
        width: 140,
      },
      {
        Header: "조",
        accessor: "name",
        width: 140,
      },
    ],
    [],
  );

  return (
    <Modal>
      <Modal.Header>
        <Title>조 검색하기</Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Control
                      name="name"
                      placeholder="조 이름"
                      ref={register}
                    />
                  </Form.Group>
                </Col>
                <SearchButtonColumn md={{ offset: 2, span: 4 }}>
                  <Button type="submit">검색하기</Button>
                </SearchButtonColumn>
              </Row>
            </Form>
          </Col>
        </Row>
        <GroupsTableRow>
          <Col>
            <GroupsTable
              columns={columns}
              currentPage={state.page}
              data={state.riderGroup1s}
              pageCount={state.pageCount}
              pageSize={state.pageSize}
              onSelect={handleSelectGroup1}
              goToPage={handleGoToPage}
              nextPage={handleNextPage}
              previousPage={handlePreviousPage}
              setPageSize={handleSetPageSize}
            />
          </Col>
        </GroupsTableRow>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSelect}>선택하기</Button>
      </Modal.Footer>
    </Modal>
  );
};
