import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  FETCH_GROUP_1: "FETCH_GROUP_1",
  FETCH_GROUP_1_NO_PAGINATION: "FETCH_GROUP_1_NO_PAGINATION",
  FETCH_GROUP_2: "FETCH_GROUP_2",
  SELECT_RIDER_GROUP_2: "SELECT_RIDER_GROUP_2",
};

const INITIAL_STATE = {
  page: 1,
  pageCount: 1,
  pageSize: 10,
  riderGroup1s: [],
  riderGroup2s: [],

  selectedRiderGroup2: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_GROUP_1:
      return {
        ...state,
        riderGroup1s: action.riderGroup1s,
        page: action.page,
        pageCount: action.pageCount,
        pageSize: action.pageSize,
      };
    case CONSTANTS.FETCH_GROUP_1_NO_PAGINATION:
      return {
        ...state,
        riderGroup1s: action.riderGroup1s,
      };
    case CONSTANTS.FETCH_GROUP_2:
      return {
        ...state,
        riderGroup2s: action.riderGroup2s,
      };
    case CONSTANTS.SELECT_RIDER_GROUP_2:
      return {
        ...state,
        selectedRiderGroup2: action.selectedRiderGroup2,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useRiderGroupsStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const fetchRiderGroup1 = useCallback(
    async ({
      name = "",
      page = state.page,
      pageSize = state.pageSize,
    } = {}) => {
      const { riderGroup1s, pageCount } = await api.get("/rider-group1s", {
        name,
        page,
        pageSize,
      });

      dispatch({
        type: CONSTANTS.FETCH_GROUP_1,
        riderGroup1s,
        page,
        pageCount,
        pageSize,
      });
    },
    [api.get, dispatch, state.page, state.pageSize],
  );

  return {
    state,
    fetchRiderGroup1,
  };
};
