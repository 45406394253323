import React, {
  createRef,
  forwardRef,
  useEffect
} from "react";


export default forwardRef(({
  indeterminate,
  ...rest
}, ref) => {
  const defaultRef = createRef(null);
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input type = "checkbox"
  ref = {
    resolvedRef
  } {
    ...rest
  }
  />;
});