import React from "react";
import Form from "react-bootstrap/Form";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import { useAuthStore, useLoadingStore, useModalStore } from "@/store/hooks";

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`

const Title = styled.h3``;

const EditUserPasswordModal = () => {
  const { ...actions } = useAuthStore();
  const { closeModal } = useModalStore();
  const { errors, register, handleSubmit } = useForm();
  const { finishLoading, startLoading } = useLoadingStore();

  function handleClose() {
    closeModal();
  }

  const onSubmit = ({ oldPassword, newPassword }) => {
    startLoading();

    actions
      .updatePassword({ oldPassword, newPassword })
      .then(() => {
        finishLoading();
        closeModal();
        window.alert("변경되었습니다.");
      })
      .catch((e) => {
        finishLoading();
        window.alert(e.message);
      });
  };

  return (
    <Modal size="lg">
      <Modal.Header>
        <Title>비밀번호 변경</Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Control
              name="oldPassword"
              placeholder="이전 비밀번호"
              ref={register({ required: true })}
              type="password"
            />
            <ErrorText>{errors.oldPassword && "이전 비밀번호를 입력해주세요."}</ErrorText>
          </Form.Group>
          <Form.Group>
            <Form.Control
              name="newPassword"
              placeholder="새 비밀번호"
              ref={register({ required: true })}
              type="password"
            />
            <ErrorText>{errors.newPassword && "새 비밀번호 입력해주세요."}</ErrorText>
          </Form.Group>
          <Button type="submit">확인</Button>{" "}
          <Button onClick={handleClose}>닫기</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditUserPasswordModal;