import React, { createContext, useContext } from "react";
import api from "@/lib/api";

import { useAuthStore } from "../hooks";

export const Context = createContext({});

export const Provider = ({ children }) => {
  const { state } = useAuthStore();

  const get = (url, options = {}) => {
    return api.get(url, options).catch((e) => {
      if (e.statusCode === 401) {
        api.post("/auth/signout");
        window.location.href = "/";
      }

      throw e;
    });
  };

  const post = (url, options = {}) => {
    return api.post(url, options).catch((e) => {
      if (e.statusCode === 401 && state.user) {
        api.post("/auth/signout");
        window.location.href = "/";
      }

      throw e;
    });
  };

  const put = (url, options = {}) => {
    return api.put(url, options).catch((e) => {
      if (e.statusCode === 401) {
        api.post("/auth/signout");
        window.location.href = "/";
      }

      throw e;
    });
  };

  const putFile = (url, options = {}) => {
    return api.putFile(url, options).catch((e) => {
      if (e.statusCode === 401) {
        api.post("/auth/signout");
        window.location.href = "/";
      }

      throw e;
    });
  };

  const del = (url, options = {}) => {
    return api.delete(url, options).catch((e) => {
      if (e.statusCode === 401) {
        api.post("/auth/signout");
        window.location.href = "/";
      }

      throw e;
    });
  };

  return (
    <Context.Provider value={{ get, post, put, putFile, del }}>
      {children}
    </Context.Provider>
  );
};

export const useApiStore = () => useContext(Context);
