import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import _cloneDeep from "lodash/cloneDeep";
import _remove from "lodash/remove";

import { Rider } from "@/Models";
import { useApiStore, useErrorsStore } from "@/store/hooks";

export const CONSTANTS = {
  CLEAR: "CLEAR",
  CREATE: "CREATE",
  DELETE: "DELETE",
  FETCH_ALL: "FETCH_ALL",
  FETCH_ALL_NO_DELETED: "FETCH_ALL_NO_DELETED",
  FETCH_ALL_NO_PAGINATION: "FETCH_ALL_NO_PAGINATION",
  FETCH_ALL_RIDER_GROUP2S: "FETCH_ALL_RIDER_GROUP2S",
  SET_MODAL_QUERY: "SET_MODAL_QUERY",
  SET_QUERY: "SET_QUERY",
  UPDATE: "UPDATE",
};

const INITIAL_STATE = {
  riders: [],
  riderGroup2s: [],
  page: 1,
  pageCount: 1,
  pageSize: 10,
  ridersNoDeleted: [],
  ridersNoPagination: new Map(),
  query: {
    text: "",
  },
  modalQuery: {
    text: "",
    withDeleted: 1,
  },
};

export const ORDER_BY_NAME = "name";
export const ORDER_BY_GOURP_NAME = "group-name";

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.CREATE:
      return {
        ...state,
      };
    case CONSTANTS.UPDATE:
      return {
        ...state,
      };
    case CONSTANTS.CLEAR:
      return INITIAL_STATE;
    case CONSTANTS.DELETE:
      return {
        ...state,
        riders: _remove(state.riders, (rider) => rider.id !== action.id),
      };
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        riders: action.riders,
        page: action.page,
        pageCount: action.pageCount,
        pageSize: action.pageSize,
      };
    case CONSTANTS.FETCH_ALL_NO_DELETED:
      return {
        ...state,
        ridersNoDeleted: action.ridersNoDeleted,
        page: action.page,
        pageCount: action.pageCount,
        pageSize: action.pageSize,
      };
    case CONSTANTS.FETCH_ALL_NO_PAGINATION:
      return {
        ...state,
        ridersNoPagination: action.ridersNoPagination,
      };
    case CONSTANTS.FETCH_ALL_RIDER_GROUP2S:
      return {
        ...state,
        riderGroup2s: action.riderGroup2s,
      };
    case CONSTANTS.SET_MODAL_QUERY:
      return {
        ...state,
        modalQuery: {
          ...state.modalQuery,
          ...action.modalQuery,
        },
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useRidersStore = () => {
  const api = useApiStore();
  const { actions: errorsActions } = useErrorsStore();
  const { dispatch, state } = useContext(Context);

  const clear = useCallback(() => {
    dispatch({
      type: CONSTANTS.CLEAR,
    });
  }, [dispatch]);

  const create = useCallback(
    async ({
      code,
      name,
      nickname,
      mobile1,
      mobile2,
      car,
      memo1,
      memo2,
      riderGroup1Id,
    }) => {
      return await api.post("/riders", {
        code,
        name,
        nickname,
        mobile1,
        mobile2,
        car,
        memo1,
        memo2,
        riderGroup1Id,
      });
    },
    [api.post],
  );

  const deleteRider = useCallback(
    (id, options = {}) => {
      return api.del(`/riders/${id}`, options);
    },
    [api.del],
  );

  const fetchAll = useCallback(
    async ({ page = state.page, pageSize = state.pageSize } = {}) => {
      try {
        const { riders, pageCount } = await api.get(
          "/riders/by-rider-group-managers",
          {
            page,
            pageSize,
            text: state.query.text,
            orderBy: ORDER_BY_GOURP_NAME,
          },
        );

        dispatch({
          type: CONSTANTS.FETCH_ALL,
          riders: riders.map((r) => new Rider(r)),
          page,
          pageCount,
          pageSize,
        });
      } catch (e) {
        errorsActions.apiError({
          ...e,
          humanMessage: "라이더 목록을 불러오는데 실패하였습니다.",
        });
      }
    },
    [
      api.get,
      dispatch,
      errorsActions.apiError,
      state.page,
      state.pageSize,
      state.query.text,
    ],
  );

  const fetchAllNoDeleted = useCallback(
    async ({ page = state.page, pageSize = state.pageSize } = {}) => {
      try {
        const { riders, pageCount } = await api.get("/riders", {
          page,
          pageSize,
          text: state.modalQuery.text,
          withDeleted: state.modalQuery.withDeleted,
          orderBy: ORDER_BY_GOURP_NAME,
        });

        dispatch({
          type: CONSTANTS.FETCH_ALL_NO_DELETED,
          ridersNoDeleted: riders.map((r) => new Rider(r)),
          page,
          pageCount,
          pageSize,
        });
      } catch (e) {
        errorsActions.apiError({
          ...e,
          humanMessage: "라이더 목록을 불러오는데 실패하였습니다.",
        });
      }
    },
    [
      api.get,
      dispatch,
      errorsActions.apiError,
      state.page,
      state.pageSize,
      state.modalQuery.text,
      state.modalQuery.withDeleted,
    ],
  );

  const fetchAllNoDeletedForRider = useCallback(
    async ({ page = state.page, pageSize = state.pageSize } = {}) => {
      try {
        const { riders, pageCount } = await api.get("/rider-managers/riders", {
          page,
          pageSize,
          text: state.modalQuery.text,
          orderBy: ORDER_BY_GOURP_NAME,
        });

        dispatch({
          type: CONSTANTS.FETCH_ALL_NO_DELETED,
          ridersNoDeleted: riders,
          page,
          pageCount,
          pageSize,
        });
      } catch (e) {
        errorsActions.apiError({
          ...e,
          humanMessage: "라이더 목록을 불러오는데 실패하였습니다.",
        });
      }
    },
    [
      api.get,
      dispatch,
      errorsActions.apiError,
      state.page,
      state.pageSize,
      state.modalQuery.text,
      state.modalQuery.withDeleted,
    ],
  );

  const fetchAllRiderGroup2s = useCallback(async () => {
    try {
      const riderGroup2s = await api.get("/rider-group2s");
      dispatch({
        type: CONSTANTS.FETCH_ALL_RIDER_GROUP2S,
        riderGroup2s,
      });
      return riderGroup2s;
    } catch (e) {
      errorsActions.apiError({
        ...e,
        humanMessage: "라이더 조 목록을 불러오는데 실패하였습니다.",
      });
      throw e;
    }
  }, [api.get, dispatch, errorsActions.apiError]);

  const fetchByIdOnTheFly = useCallback(
    (id) => {
      return api.get(`/riders/${id}`);
    },
    [api.get],
  );

  const setModalQuery = useCallback(
    (modalQuery = {}) => {
      dispatch({
        type: CONSTANTS.SET_MODAL_QUERY,
        modalQuery,
      });
    },
    [dispatch],
  );

  const setQuery = useCallback(
    (query = {}) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch],
  );

  const update = useCallback(
    (
      id,
      { name, nickname, mobile1, mobile2, car, memo1, memo2, riderGroup1Id },
    ) => {
      return api.put(`/riders/${id}`, {
        name,
        nickname,
        mobile1,
        mobile2,
        car,
        memo1,
        memo2,
        riderGroup1Id,
      });
    },
    [api.put],
  );

  const updatePassword = useCallback(
    (id, { password }) => {
      return api.put(`/riders/${id}/password`, {
        password,
      });
    },
    [api.put],
  );

  return {
    state,
    clear,
    create,
    deleteRider,
    fetchAll,
    fetchAllNoDeleted,
    fetchAllNoDeletedForRider,
    fetchAllRiderGroup2s,
    fetchByIdOnTheFly,
    setModalQuery,
    setQuery,
    update,
    updatePassword,
  };
};
