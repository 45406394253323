import React, { useState } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import { FindGroup1Modal } from "@/Modals";
import { useModalStore, useRidersStore } from "@/store/hooks";

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`;

const StyledRow = styled(OrigRow)`
  margin-left: -5px;
  margin-right: -5px;
`;
const StyledCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

const Title = styled.h3`
  margin: 0;
`;

export default ({ onCreate = _noop } = {}) => {
  const [selectedGroup1, setSelectedGroup1] = useState(null);
  const { closeModal, openModal } = useModalStore();
  const { ...ridersActions } = useRidersStore();
  const { register, handleSubmit, errors, reset, getValues } = useForm();

  function handleClickFindGroup1() {
    openModal(
      <FindGroup1Modal
        onSelect={handleSelectGroup1s}
        key="find-group1-modal"
      />,
    );
  }

  function handleSelectGroup1s(riderGroup1) {
    closeModal();
    setSelectedGroup1(riderGroup1[0]);
    reset({
      name: getValues().name,
      nickname: getValues().nickname,
      mobile1: getValues().mobile1,
      mobiel2: getValues().mobile2,
      car: getValues().car,
      memo1: getValues().memo1,
      memo2: getValues().memo2,
      riderGroup1: `${
        riderGroup1[0].riderGroup2 ? `${riderGroup1[0].riderGroup2.name}/` : ""
      }${riderGroup1[0].name}`,
    });
  }

  const onSubmit = async ({
    name,
    nickname,
    mobile1,
    mobile2,
    car,
    memo1,
    memo2,
  }) => {
    if (selectedGroup1) {
      ridersActions
        .create({
          name,
          nickname,
          mobile1,
          mobile2,
          car,
          memo1,
          memo2,
          riderGroup1Id: selectedGroup1.id,
        })
        .then(() => {
          onCreate();
          window.alert(`${name} 라이더를 추가하였습니다.`);

          closeModal();
        })
        .catch((e) => {
          window.alert(`라이더 생성에 실패했습니다: ${e.message}`);
        });
    }
  };

  return (
    <Modal>
      <Modal.Header>
        <Title>라이더 생성</Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={StyledRow}>
            <Form.Label column sm={4}>
              팀/조*
            </Form.Label>
            <StyledCol sm={6}>
              <Form.Control
                readOnly
                name="riderGroup1"
                ref={register({ required: "팀/조를 선택해주세요." })}
              />
              <ErrorText>
                {errors.riderGroup1 && errors.riderGroup1.message}
              </ErrorText>
            </StyledCol>
            <StyledCol sm={2}>
              <Button type="button" onClick={handleClickFindGroup1}>
                검색
              </Button>
            </StyledCol>
          </Form.Group>
          <Form.Group as={StyledRow}>
            <Form.Label column sm={4}>
              라이더명*
            </Form.Label>
            <StyledCol sm={8}>
              <Form.Control
                placeholder="홍길동"
                name="name"
                ref={register({ required: "라이더명을 입력해주세요." })}
              />
              <ErrorText>{errors.name && errors.name.message}</ErrorText>
            </StyledCol>
          </Form.Group>
          <Form.Group as={StyledRow}>
            <Form.Label column sm={4}>
              닉네임
            </Form.Label>
            <StyledCol sm={8}>
              <Form.Control
                placeholder="닉네임"
                name="nickname"
                ref={register}
              />
            </StyledCol>
          </Form.Group>
          <Form.Group as={StyledRow}>
            <Form.Label column sm={4}>
              휴대폰1*
            </Form.Label>
            <StyledCol sm={8}>
              <Form.Control
                type="tel"
                placeholder="01012341234"
                name="mobile1"
                ref={register({ required: "휴대폰 번호를 입력해주세요." })}
              />
              <ErrorText>{errors.mobile1 && errors.mobile1.message}</ErrorText>
            </StyledCol>
          </Form.Group>
          <Form.Group as={StyledRow}>
            <Form.Label column sm={4}>
              휴대폰2
            </Form.Label>
            <StyledCol sm={8}>
              <Form.Control
                type="tel"
                name="mobile2"
                placeholder="01012341234"
                ref={register}
              />
            </StyledCol>
          </Form.Group>
          <Form.Group as={StyledRow}>
            <Form.Label column sm={4}>
              차량번호
            </Form.Label>
            <StyledCol sm={8}>
              <Form.Control name="car" ref={register} />
            </StyledCol>
          </Form.Group>
          <Form.Group as={StyledRow}>
            <Form.Label column sm={4}>
              메모1
            </Form.Label>
            <StyledCol sm={8}>
              <Form.Control
                as="textarea"
                rows="3"
                name="memo1"
                ref={register}
              />
            </StyledCol>
          </Form.Group>
          <Form.Group as={StyledRow}>
            <Form.Label column sm={4}>
              메모2
            </Form.Label>
            <StyledCol sm={8}>
              <Form.Control
                as="textarea"
                rows="3"
                name="memo2"
                ref={register}
              />
            </StyledCol>
          </Form.Group>
          <Form.Group as={StyledRow}>
            <StyledCol sm={{ span: 8, offset: 4 }}>
              <Button type="submit">생성하기</Button>
            </StyledCol>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
