import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import _findIndex from "lodash/findIndex";
import dfIsAfter from "date-fns/isAfter";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { saveAs } from "file-saver";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button, SmallButton } from "@/components/Buttons";
import Table from "@/components/Table";
import { DELAY_REASONS, REASON_ETC } from "@/constants";
import { formatDate, getDateByTimeZone } from "@/lib/date";
import {
  DeliveryForRiderModal,
  FindRiderModal,
  WaybillModal,
  SelectDelayReasonModal,
} from "@/Modals";
import { DeliveriesStore } from "@/store";
import {
  useAuthStore,
  useDeliveriesStore,
  useLoadingStore,
  useModalStore,
} from "@/store/hooks";

const ButtonsCol = styled(Col)`
  margin-left: -2.5px;
  margin-right: -2.5px;

  button {
    margin-left: 2.5px;
    margin-right: 2.5px;
    margin-bottom: 5px;
  }
`;

const ButtonItem = styled(Button)`
  margin-right: 5px;
`;

const CheckBoxRow = styled(Row)`
  margin-top: 5px;
`;

const ColFlex = styled(Col)`
  flex: ${({ flex }) => flex || 1};
  padding-left: 5px;
  padding-right: 5px;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
`;

const CounterCol = styled(Col)`
  display: inline-block;
  margin: 10px 0;
  text-align: right;
`;

const DatePickerCol = styled(Col)`
  padding: 0;
  display: flex;
  flex-direction: row;

  input {
    width: calc(50% - 15px);
  }
`;

const DeliveriesTable = styled(Table)`
  font-size: 14px;
  min-width: 1800px;
`;

const FormLabel = styled(Form.Label)`
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  text-align: right;
`;

const FormRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`;

const PaddingCol = styled(Col)`
  padding: 0;
  padding-left: ${(props) => (props.left ? props.left : 0)}px;
`;

const SubmitCol = styled(ColFlex)`
  text-align: right;
  margin-bottom: 10px;
`;

const TableRow = styled(Row)`
  overflow: auto;
  flex: 1;
`;
const TableCol = styled(Col)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Wave = styled.span`
  margin: 0 5px;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 20px);
`;

const ClickableColumn = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const BookIdColumn = ({ delivery }) => {
  const { openModal } = useModalStore();

  function handleClick() {
    openModal(
      <DeliveryForRiderModal
        backdrop
        key="book-detail-modal"
        bookId={delivery.bookId}
        type={delivery.type || "1"}
      />,
    );
  }

  return (
    <ClickableColumn onClick={handleClick}>
      {delivery.renderBookId()}
    </ClickableColumn>
  );
};

const CounterContainer = memo(({ fetch, fetchTime }) => {
  const { loading } = useLoadingStore().state;
  const { openedModal } = useModalStore().state;

  const sec = 1000;
  const [count, setCount] = useState(600 * sec);
  const [pause, setPause] = useState(false);

  const handlePause = useCallback(() => {
    setPause(!pause);
  }, [pause]);

  const counter = useCallback(() => {
    if (count === 0) {
      setCount(600000);

      if (!loading) {
        fetch();
      }
    } else {
      setCount(count - sec);
    }
  }, [count, setCount, loading]);

  useEffect(() => {
    if (!pause && !openedModal) {
      let id = setInterval(counter, sec);
      return () => clearInterval(id);
    }
  }, [pause, openedModal, counter]);

  return (
    <CounterCol>
      {parseInt(count / 60000)}분 {(count % 60000) / 1000}초 남았습니다 (최근
      호출 시간 : {formatDate(getDateByTimeZone(fetchTime), "HH:mm:ss")}){" "}
      <SmallButton onClick={() => handlePause(!pause)} variant="warning">
        {pause || openedModal ? "시작" : "일시정지"}
      </SmallButton>
      <SmallButton onClick={fetch} variant="success">
        새로고침
      </SmallButton>
    </CounterCol>
  );
});

const SearchContainer = memo(() => {
  const { user } = useAuthStore().state;

  const { state, ...actions } = useDeliveriesStore();
  const { closeModal, openModal } = useModalStore();

  const { handleSubmit, register, reset, getValues, setValue } = useForm();

  useEffect(() => {
    reset({
      ...state.query,
    });
  }, []);

  const handleChange = useCallback((e) => {
    if (e.target.name === "dateFrom" && e.target.value > getValues().dateTo) {
      setValue("dateTo", e.target.value);
    }

    if (e.target.name === "dateTo" && e.target.value < getValues().dateFrom) {
      setValue("dateFrom", e.target.value);
    }
  }, []);

  const handleClear = useCallback(() => {
    actions.resetQuery();
  }, [actions.resetQuery]);

  const handleSelectRider = useCallback(
    (rider) => {
      closeModal();
      actions.setQuery({
        rider,
      });
    },
    [actions.setQuery, closeModal],
  );

  const handleClickFindRider = useCallback(() => {
    openModal(
      <FindRiderModal onSelect={handleSelectRider} key="find-rider-modal" />,
    );
  }, [handleSelectRider, openModal]);

  const onSubmit = useCallback((data) => {
    actions.setQuery(data);
  }, []);

  let isMinDate = false;
  if (
    user.username === "pch@fsdcorp.kr" ||
    user.username === "kjaein77@fsdcorp.kr"
  ) {
    isMinDate = true;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormRow>
        <ColFlex flex={4}>
          <Row>
            <ColFlex sm={1}>
              <Form.Group>
                <Form.Control as="select" custom name="types" ref={register}>
                  <option value="1;2;3">전체</option>
                  <option value="1">일반</option>
                  <option value="2;3">반품/픽업</option>
                </Form.Control>
              </Form.Group>
            </ColFlex>
            <ColFlex sm={1}>
              <Form.Group>
                <Form.Control as="select" custom name="dateType" ref={register}>
                  <option value={DeliveriesStore.QUERY_DATE_TYPE_RECEIPT_DATE}>
                    접수일자
                  </option>
                  <option
                    value={
                      DeliveriesStore.QUERY_DATE_TYPE_DELIVERY_ALLOCATION_DATE
                    }
                  >
                    배송배차일자
                  </option>
                  <option
                    value={
                      DeliveriesStore.QUERY_DATE_TYPE_PICKUP_COMPLETED_DATE
                    }
                  >
                    수거완료일자
                  </option>
                  <option
                    value={
                      DeliveriesStore.QUERY_DATE_TYPE_DELIVERY_COMPLETED_DATE
                    }
                  >
                    배송완료일자
                  </option>
                </Form.Control>
              </Form.Group>
            </ColFlex>
            <DatePickerCol sm={4}>
              <Form.Control
                id="start"
                name="dateFrom"
                type="date"
                ref={register}
                onChange={handleChange}
                min={isMinDate ? "2024-02-01" : null}
              />
              <Wave>~</Wave>
              <Form.Control
                id="end"
                name="dateTo"
                type="date"
                ref={register}
                onChange={handleChange}
              />
            </DatePickerCol>
          </Row>
        </ColFlex>
        <ColFlex>
          <Row>
            <FormLabel column sm={3}>
              라이더
            </FormLabel>
            <PaddingCol left={20}>
              <Form.Group>
                <Form.Control
                  readOnly
                  name="riderName"
                  value={state.query.rider && state.query.rider.name}
                />
              </Form.Group>
            </PaddingCol>
            <PaddingCol sm={3}>
              <Button variant="success" onClick={handleClickFindRider}>
                찾기
              </Button>
            </PaddingCol>
          </Row>
        </ColFlex>
      </FormRow>

      <FormRow>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              송하인명
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="senderName" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              송하인 연락처
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="senderMobile" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              출발동
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="senderDong" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              수거자
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="pickupRiderName" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              지역
            </FormLabel>
            <Col sm={8}>
              <Form.Control as="select" name="region" ref={register} custom>
                <option value="">전체</option>
                <option value="SEOUL">서울</option>
                <option value="GOYANG">고양</option>
                <option value="BUCHEON">부천</option>
                <option value="SEONGNAM">성남</option>
                <option value="HANAM">하남</option>
                <option value="GWANGMYEONG">광명</option>
                <option value="GWACHEON">과천</option>
                <option value="GUNPO">군포</option>
                <option value="SUWON">수원</option>
                <option value="ANSAN">안산</option>
                <option value="ANYANG">안양</option>
                <option value="UIWANG">의왕</option>
                <option value="YONGIN">용인</option>
                <option value="HWASEONG">화성</option>
                <option value="GIMPO">김포</option>
                <option value="GWANGJU">광주</option>
                <option value="INCHEON">인천</option>
                <option value="BUSAN">부산</option>
                <option value="GYUNGNAM_YANGSAN">경남양산</option>
                <option value="ULSAN">울산</option>
                <option value="GYUNGNAM_GIMHAE">경남김해</option>
              </Form.Control>
            </Col>
          </Form.Group>
        </ColFlex>
      </FormRow>

      <FormRow>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              수하인명
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="receiverName" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              수하인 연락처
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="receiverMobile" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              도착동
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="receiverDong" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              도로명
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="receiverAddressRoad" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              배송자
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="deliveryRiderName" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
      </FormRow>

      <FormRow>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              예약번호
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="bookId" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              사측주문번호
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="orderIdFromCorp" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              기업회원명
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="corpUserName" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <ColFlex>
          <Form.Group as={Row}>
            <FormLabel column sm={4}>
              접수점
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="spotName" ref={register} />
            </Col>
          </Form.Group>
        </ColFlex>
        <SubmitCol>
          <Button variant="danger" onClick={handleClear}>
            초기화
          </Button>{" "}
          <Button variant="success" type="submit">
            조회
          </Button>
        </SubmitCol>
      </FormRow>
    </Form>
  );
});

const Deliveries = memo(() => {
  const params = new URLSearchParams(window.location.search);
  const type = params.get("type");

  const { user } = useAuthStore().state;

  const { state, ...actions } = useDeliveriesStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { closeModal, openModal } = useModalStore();
  const { getValues, register, setValue } = useForm();
  const [selectedDeliveries, setSelectedDeliveries] = useState([]);

  const [loaded, setLoaded] = useState(false); // 첫 fetch 진행

  // TODO: 리팩토링 필요
  const handleChangeCheckBoxForm = useCallback(() => {
    let _queryTypes = getValues("queryTypes");

    const includedAll = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ALL,
    );
    const addedAll = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ALL,
    );

    // 전체를 선택한 경우
    // 전체 해제된 경우
    if (_queryTypes.length === 0 || (!includedAll && addedAll)) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_ALL];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    // 미배송
    const addedDelayed = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED,
    );
    const includedDelayed = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED,
    );

    if (addedDelayed && !includedDelayed) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedDelayed &&
      includedDelayed &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED,
      );
    }

    // 픽업 & 반품을 선택한 경우
    const addedTypePickup = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_PICKUP,
    );
    const includedTypePickup = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_PICKUP,
    );

    if (!includedTypePickup && addedTypePickup) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_PICKUP];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedTypePickup &&
      addedTypePickup &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_PICKUP,
      );
    }

    const addedTypeReturn = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_RETURN,
    );
    const includedTypeReturn = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_RETURN,
    );

    if (!includedTypeReturn && addedTypeReturn) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_RETURN];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedTypeReturn &&
      addedTypeReturn &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_RETURN,
      );
    }

    // 취소 or 사고를 선택한 경우
    const addedAccident = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ACCIDENT,
    );
    const includedAccident = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ACCIDENT,
    );

    if (!includedAccident && addedAccident) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_ACCIDENT];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedAccident &&
      addedAccident &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_ACCIDENT,
      );
    }

    const addedCancel = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED,
    );
    const includedCancel = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED,
    );
    if (addedCancel && !includedCancel) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedCancel &&
      includedCancel &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED,
      );
    }

    // 송하인주소오류 / 송하인주소오류취소
    const addedSenderAddedAddressError = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR,
    );
    const includedSenderAddressError = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR,
    );
    if (addedSenderAddedAddressError && !includedSenderAddressError) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedSenderAddedAddressError &&
      includedSenderAddressError &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !== DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR,
      );
    }

    const addedSenderAddressErrorCanceled = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED,
    );
    const includedSenderAddressErrorCanceled = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED,
    );
    if (
      addedSenderAddressErrorCanceled &&
      !includedSenderAddressErrorCanceled
    ) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedSenderAddressErrorCanceled &&
      includedSenderAddressErrorCanceled &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !==
          DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED,
      );
    }

    // 수하인주소오류 / 수하인주소오류취소
    const addedReceiverAddressError = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR,
    );
    const includedReceiverAddressError = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR,
    );
    if (addedReceiverAddressError && !includedReceiverAddressError) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedReceiverAddressError &&
      includedReceiverAddressError &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !== DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR,
      );
    }

    const addedReceiverAddressErrorCanceled = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED,
    );
    const includedReceiverAddressErrorCanceled =
      state.query.queryTypes.includes(
        DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED,
      );
    if (
      addedReceiverAddressErrorCanceled &&
      !includedReceiverAddressErrorCanceled
    ) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedReceiverAddressErrorCanceled &&
      includedReceiverAddressErrorCanceled &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !==
          DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED,
      );
    }

    // 배송 불가 지역을 선택한 경우
    const addedAddressNotSupported = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED,
    );
    const includedAddressNotSupported = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED,
    );

    if (!includedAddressNotSupported && addedAddressNotSupported) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedAddressNotSupported &&
      addedAddressNotSupported &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !== DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED,
      );
    }

    const addedAddressNotSupportedCanceled = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED,
    );
    const includedAddressNotSupportedCanceled = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED,
    );

    if (
      !includedAddressNotSupportedCanceled &&
      addedAddressNotSupportedCanceled
    ) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedAddressNotSupportedCanceled &&
      addedAddressNotSupportedCanceled &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !==
          DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED,
      );
    }

    // 배달 대행을 선택한 경우
    const addedAgencyCorpUser = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN,
    );
    const includedAgencyCorpUser = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN,
    );

    if (!includedAgencyCorpUser && addedAgencyCorpUser) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedAgencyCorpUser &&
      addedAgencyCorpUser &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !==
          DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN,
      );
    }

    let queryTypes = _queryTypes;
    if (queryTypes.indexOf(DeliveriesStore.QUERY_DELIVERY_TYPE_ALL) > -1) {
      const index = _findIndex(
        queryTypes,
        (t) => t === DeliveriesStore.QUERY_DELIVERY_TYPE_ALL,
      );
      queryTypes = [
        ...queryTypes.slice(0, index),
        ...queryTypes.slice(index + 1),
      ];
    }
    queryTypes = queryTypes.filter((t) => !!t);

    setValue("queryTypes", queryTypes);
    actions.setQuery({
      queryTypes,
    });
  }, [actions.setQuery, state.query.queryTypes]);

  const [fetchTime, setFetchTime] = useState();

  const fetch = () => {
    fetchAll();
    fetchCount();
  };

  const fetchAll = useCallback(async () => {
    try {
      if (state.query) {
        const includedDelayed = state.query.queryTypes.includes(
          DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED,
        );

        if (!includedDelayed) {
          startLoading();

          await actions.fetchAll({ query: state.query });
          finishLoading();

          setFetchTime(new Date());
        } else {
          fetchDelayed();
        }
      }

      setLoaded(true);
    } catch (e) {
      window.alert(
        `배송정보를 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`,
      );
    }

    finishLoading();
  }, [actions.fetchAll, state.query]);

  const fetchCount = useCallback(async () => {
    try {
      if (state.query) {
        const includedDelayed = state.query.queryTypes.includes(
          DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED,
        );

        if (!includedDelayed) {
          await actions.countByStatus({ query: state.query });
        } else {
          fetchDelayed();
        }
      }

      setLoaded(true);
    } catch (e) {}
  }, [actions.countByStatus, state.query]);

  const fetchDelayed = useCallback(async () => {
    try {
      if (state.query) {
        startLoading();

        await actions.fetchAllDelayed({ query: state.query });
        finishLoading();

        await actions.countByStatus({ query: state.query });
      }
    } catch (e) {
      window.alert(
        `배송정보를 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`,
      );
    }

    finishLoading();
  }, [actions.countByStatus, actions.fetchAllDelayed, state.query]);

  useEffect(() => {
    handleClear();
    setValue("queryTypes", state.query.queryTypes);
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  const [columnWidthsObj, setColumnWidthsObj] = useState({});

  useEffect(() => {
    const columnWidths = localStorage.getItem("columnWidths");

    if (columnWidths) {
      setColumnWidthsObj(JSON.parse(columnWidths));
    }
  }, []);

  useEffect(() => {
    if (loaded) {
      if (state.query.page > 1) {
        actions.setQuery({ page: 1 });
      } else {
        fetch();
      }
    }
  }, [
    state.query.bookId,
    state.query.corpUserName,
    state.query.dateType,
    state.query.dateFrom,
    state.query.dateTo,
    state.query.orderIdFromCorp,
    state.query.receiverAddressRoad,
    state.query.receiverDong,
    state.query.receiverMobile,
    state.query.receiverName,
    state.query.region,
    state.query.rider,
    state.query.pickupRiderName,
    state.query.deliveryRiderName,
    state.query.senderDong,
    state.query.spotName,
    state.query.senderName,
    state.query.senderMobile,
    state.query.queryTypes,
    state.query.types,
  ]);

  useEffect(() => {
    if (loaded) {
      fetch();
    }
  }, [state.query.page, state.query.pageSize]);

  useEffect(() => {
    if (loaded) {
      fetchAll();
    }
  }, [state.query.orderBy]);

  useEffect(() => {
    if (loaded && state.reset) {
      fetch();
      actions.reset(false);
    }
  }, [state.reset]);

  useEffect(() => {
    const changedDateFrom = getDateByTimeZone(state.query.dateFrom);
    const changedDateTo = getDateByTimeZone(state.query.dateTo);
    if (dfIsAfter(changedDateFrom, changedDateTo)) {
      actions.setQuery({
        dateFrom: state.query.dateFrom,
        dateTo: state.query.dateTo,
      });
    }
  }, [state.query.dateFrom, state.query.dateTo]);

  const alertNeedToSelectLeastOne = useCallback(() => {
    window.alert("적어도 1개 이상의 배송을 선택해주세요.");
  }, []);

  const handleSelectDeliveryRider = useCallback(
    async (rider) => {
      if (
        window.confirm(
          `배송배차 ${selectedDeliveries.length}건을 ${rider.name}${
            rider.riderGroup1
              ? `(${rider.riderGroup1.riderGroup2.name}/${rider.riderGroup1.name})`
              : ``
          }에게 지정하시겠습니까?`,
        )
      ) {
        try {
          closeModal();
          const results = await actions.allocateDeliveryRiders({
            bookIds: selectedDeliveries.map((d) => d.bookId),
            riderId: rider.id,
          });
          alert(
            `배송배차 ${selectedDeliveries.length}건 중 ${
              results.success?.length
            }건을 ${rider.name}${
              rider.riderGroup1
                ? `(${rider.riderGroup1.riderGroup2.name}/${rider.riderGroup1.name})`
                : ``
            }에게 지정했습니다\n배송배차건: ${
              results.success?.length
            }, 실패건: ${results.failed?.length}`,
          );
          fetch();
        } catch (e) {
          alert(e.message);
        }
      }
    },
    [actions.allocateDeliveryRiders, selectedDeliveries],
  );

  const handleSelectPickupRider = useCallback(
    async (rider) => {
      if (
        window.confirm(
          `수거배차 ${selectedDeliveries.length}건을 ${rider.name}${
            rider.riderGroup1
              ? `(${rider.riderGroup1.riderGroup2.name}/${rider.riderGroup1.name})`
              : ``
          }에게 지정하시겠습니까?`,
        )
      ) {
        try {
          closeModal();
          const results = await actions.allocatePickupRiders({
            bookIds: selectedDeliveries.map((d) => d.bookId),
            riderId: rider.id,
          });

          alert(
            `수거배차 ${selectedDeliveries.length}건 중 ${
              results.success?.length
            }건을 ${rider.name}${
              rider.riderGroup1
                ? `(${rider.riderGroup1.riderGroup2.name}/${rider.riderGroup1.name})`
                : ``
            }에게 지정했습니다\n수거배차건: ${
              results.success?.length
            }, 실패건: ${results.failed?.length}`,
          );
          fetch();
        } catch (e) {
          alert(e.message);
        }
      }
    },
    [actions.allocatePickupRiders, selectedDeliveries],
  );

  const handleClear = useCallback(() => {
    actions.resetQuery();
  }, [actions.resetQuery]);

  const handleClickAllocateDeliveryRider = useCallback(() => {
    if (!selectedDeliveries.length) {
      alert("배송배차를 진행할 배송을 선택해주세요.");
      return false;
    }

    openModal(
      <FindRiderModal
        onSelect={handleSelectDeliveryRider}
        key="find-delivery-rider-modal"
      />,
    );
  }, [handleSelectDeliveryRider, openModal, selectedDeliveries]);

  const handleClickAllocatePickupRider = useCallback(() => {
    if (!selectedDeliveries.length) {
      alertNeedToSelectLeastOne();
      return;
    }

    openModal(
      <FindRiderModal
        onSelect={handleSelectPickupRider}
        key="find-pickup-rider-modal"
      />,
    );
  }, [handleSelectPickupRider, openModal, selectedDeliveries]);

  const handleClickSetPreviousStatus = useCallback(async () => {
    if (!selectedDeliveries.length) {
      alertNeedToSelectLeastOne();
      return;
    }

    const impossible = selectedDeliveries.some(
      (d) => !d.isPossibleToSetPreviousStatus,
    );

    if (impossible) {
      window.alert(
        "이전단계로 변경할 수 없는 목록이 있습니다. 다시 확인해주십시오.",
      );
      return;
    }

    const firstDeliveryBookId = selectedDeliveries[0].bookId;
    const totalCount = selectedDeliveries.length;

    if (
      window.confirm(
        `예약번호 ${firstDeliveryBookId} 포함 ${totalCount}건에 대해서 이전 단계로 변경 처리하시겠습니까?`,
      )
    ) {
      try {
        startLoading();
        await actions.setPreviousStatus(
          selectedDeliveries.map((d) => d.bookId),
        );
        finishLoading();
        window.alert(
          `예약번호 ${firstDeliveryBookId}건 포함 ${totalCount}건에 대해서 이전 단계 상태로 변경했습니다.`,
        );
        fetch();
      } catch (e) {
        finishLoading();
        window.alert(
          `이전단계로 변경하는데 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }
    }
  }, [actions.setPreviousStatus, selectedDeliveries]);

  const handleClickHeader = useCallback(
    (orderBy) => {
      actions.setQuery({ orderBy });
    },
    [actions.setQuery],
  );

  // 지연 사유
  const handleClickDelay = useCallback(() => {
    if (!selectedDeliveries.length) {
      alert("지연 사유를 입력할 배송을 선택해주세요.");
      return false;
    }

    openModal(
      <SelectDelayReasonModal
        selectedDeliveries={selectedDeliveries}
        onSubmit={handleSubmitDelay}
        key="select-delay-reason-modal"
      />,
    );
  }, [handleSelectDeliveryRider, openModal, selectedDeliveries]);

  const handleSubmitDelay = useCallback(
    async ({ reasonType, reasonEtcText }) => {
      try {
        startLoading();

        const reason = DELAY_REASONS.find((e) => e.code === reasonType);

        let data = {
          reasonType: reason.code,
          reason: `${reason.text}${
            reason.code === REASON_ETC && reasonEtcText
              ? `(${reasonEtcText})`
              : ""
          }`,
        };

        const response = await actions.setDelayReason(selectedDeliveries, data);

        const failds = response.filter((el) => el.status === "rejected");

        let text = `${selectedDeliveries.length}건 중 ${
          selectedDeliveries.length - failds.length
        }건으르 지연등록했니다.\n\n결과 목록`;

        response.map((el, idx) => {
          const _text = `\n${selectedDeliveries[idx].bookId}: ${
            el.status === "rejected" ? `실패(${el.reason?.message})` : "성공"
          }`;

          text += _text;
        });

        window.alert(text);

        closeModal();
        finishLoading();
        fetch();
      } catch (e) {
        window.alert(`지연 등록에 실패하였습니다.\n에러메시지:${e.message}`);
        finishLoading();
      }
    },
    [selectedDeliveries],
  );

  const handleClickWaybill = useCallback(async () => {
    if (!selectedDeliveries.length) {
      alertNeedToSelectLeastOne();
      return;
    }

    try {
      startLoading();

      const waybillDeliveries = await actions.fetchAllSelectedDeliveries(
        selectedDeliveries,
      );

      openModal(
        <WaybillModal key="waybill-modal" deliveries={waybillDeliveries} />,
      );
    } catch (e) {
      window.alert(
        `운송장 출력을 위한 배송목록 조회에 실패하였습니다.\n에러메시지: ${e.message}`,
      );
    }

    finishLoading();
  }, [selectedDeliveries]);

  const handleClickDownload = useCallback(async () => {
    try {
      startLoading();

      const _query = {
        ...state.query,
      };

      const result = await actions.fetchForDownload({ query: _query });

      saveAs(
        result,
        `배송목록(${state.query.dateFrom}~${state.query.dateTo}).xlsx`,
      );
    } catch (e) {
      window.alert(`목록다운에 실패하였습니다.\n에러메시지:${e.message}`);
    }

    finishLoading();
  }, [actions.fetchForDownloadTest, state.query]);

  const handleClickRestoreCancel = useCallback(async () => {
    if (!selectedDeliveries.length) {
      alertNeedToSelectLeastOne();
      return;
    }

    const impossible = selectedDeliveries.some(
      (d) => !d.isPossibleToRestoreCancel,
    );

    if (impossible) {
      window.alert("취소해제 할 수 없는 목록이 있습니다. 다시 확인해주십시오.");
      return;
    }

    const firstDeliveryBookId = selectedDeliveries[0].bookId;
    const totalCount = selectedDeliveries.length;

    if (
      window.confirm(
        `예약번호 ${firstDeliveryBookId} 포함 ${totalCount}건에 대해서 취소해제 처리하시겠습니까?`,
      )
    ) {
      try {
        await actions.restoreCancel(selectedDeliveries.map((d) => d.bookId));
        window.alert(
          `예약번호 ${firstDeliveryBookId}건 포함 ${totalCount}건에 대해서 취소해제 처리하였습니다.`,
        );
        fetch();
      } catch (e) {
        window.alert(
          `취소해제 처리하는데 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }
    }
  }, [actions.restoreCancel, selectedDeliveries]);

  const handleClickKoomin = useCallback(async () => {
    if (!selectedDeliveries.length) {
      alertNeedToSelectLeastOne();
      return;
    }

    const impossible = selectedDeliveries.some((d) => !d.isPossibleToKookmin);

    if (impossible) {
      window.alert("배달접수 할 수 없는 목록이 있습니다. 다시 확인해주십시오.");
      return;
    }

    const firstDeliveryBookId = selectedDeliveries[0].bookId;
    const totalCount = selectedDeliveries.length;

    if (
      window.confirm(
        `예약번호 ${firstDeliveryBookId} 포함 ${totalCount}건에 대해서 배달접수 처리하시겠습니까?`,
      )
    ) {
      try {
        startLoading();

        const response = await actions.allocateAgencyBulk({
          deliveries: selectedDeliveries,
        });

        const failds = response.filter((el) => el.status === "rejected");

        let text = `${selectedDeliveries.length}건 중 ${
          selectedDeliveries.length - failds.length
        }건을 배달 접수했습니다.\n\n결과 목록`;

        response.map((el, idx) => {
          const _text = `\n${selectedDeliveries[idx].bookId}: ${
            el.status === "rejected" ? `실패(${el.reason?.message})` : "성공"
          }`;

          text += _text;
        });

        window.alert(text);

        fetch();
      } catch (e) {
        window.alert(
          `배달접수 처리하는데 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }

      finishLoading();
    }
  }, [selectedDeliveries, actions.allocateAgencyBulk]);

  const handleClickCancelKoomin = useCallback(async () => {
    if (!selectedDeliveries.length) {
      alertNeedToSelectLeastOne();
      return;
    }

    const impossible = selectedDeliveries.some(
      (d) => !d.isPossibleToCancelKookmin,
    );

    if (impossible) {
      window.alert(
        "배달접수 취소 할 수 없는 목록이 있습니다. 다시 확인해주십시오.",
      );
      return;
    }

    const firstDeliveryBookId = selectedDeliveries[0].bookId;
    const totalCount = selectedDeliveries.length;

    if (
      window.confirm(
        `예약번호 ${firstDeliveryBookId} 포함 ${totalCount}건에 대해서 배달접수 취소 처리하시겠습니까?`,
      )
    ) {
      try {
        const response = await actions.cancelAgencyBulk({
          deliveries: selectedDeliveries,
        });

        const failds = response.filter((el) => el.status === "rejected");

        let text = `${selectedDeliveries.length}건 중 ${
          selectedDeliveries.length - failds.length
        }건을 배달 접수 취소했습니다.\n\n결과 목록`;

        response.map((el, idx) => {
          const _text = `\n${selectedDeliveries[idx].bookId}: ${
            el.status === "rejected" ? `실패(${el.reason?.message})` : "성공"
          }`;

          text += _text;
        });

        window.alert(text);

        fetch();
      } catch (e) {
        window.alert(
          `배달접수 취소 처리하는데 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }
    }
  }, [selectedDeliveries, actions.cancelAgencyBulk]);

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({ page });
    },
    [actions.setQuery],
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery],
  );

  const handleSelect = useCallback(
    ({ selectedRows }) => {
      setSelectedDeliveries(selectedRows);
    },
    [setSelectedDeliveries],
  );

  const columns = useMemo(
    () => [
      {
        Header: "예약번호",
        id: "bookId",
        accessor: (row) => <BookIdColumn delivery={row} />,
        selectable: false,
        width: columnWidthsObj.bookId || 120,
        sortable: true,
      },
      {
        Header: "접수일시",
        accessor: (row) => row.renderReceiptDate(),
        id: "RECEIPT_DATE",
        width: columnWidthsObj.RECEIPT_DATE || 100,
        sortable: true,
      },
      {
        Header: "수지",
        accessor: (row) => row.renderPickupDateScheduled("HH:mm"),
        id: "PICKUP_SCHEDULED_DATE",
        width: columnWidthsObj.PICKUP_SCHEDULED_DATE || 60,
        sortable: true,
      },
      {
        Header: "수완",
        accessor: (row) => row.renderPickupDateCompleted("HH:mm"),
        id: "PICKUP_COMPLETED",
        width: columnWidthsObj.PICKUP_COMPLETED || 60,
        sortable: true,
      },
      {
        Header: "입고",
        accessor: (row) => row.renderWarehousedAt("HH:mm"),
        id: "WAREHOUSED",
        width: columnWidthsObj.WAREHOUSED || 60,
        sortable: true,
      },
      {
        Header: "배차",
        accessor: (row) => row.renderDeliveryAllocatedDate(),
        id: "DELIVERY_ALLOCATED",
        width: columnWidthsObj.DELIVERY_ALLOCATED || 90,
        sortable: true,
      },
      {
        Header: "배출",
        accessor: (row) => row.renderReleasedAt(),
        id: "DELIVERY_STARTED",
        width: columnWidthsObj.DELIVERY_STARTED || 90,
        sortable: true,
      },
      {
        Header: "배완",
        accessor: (row) => row.renderDeliveryCompletedDate(),
        id: "DELIVERY_COMPLETED",
        width: columnWidthsObj.DELIVERY_COMPLETED || 90,
        sortable: true,
      },
      {
        Header: "접수점",
        accessor: (row) => row.spotName,
        id: "SPOT_NAME",
        width: columnWidthsObj.SPOT_NAME || 150,
        sortable: true,
      },
      {
        Header: "출발동",
        accessor: (row) => row.senderDongName,
        id: "SENDER_DONG",
        width: columnWidthsObj.SENDER_DONG || 90,
        sortable: true,
      },
      {
        Header: "송하인",
        accessor: (row) => row.senderName,
        id: "SENDER_NAME",
        width: columnWidthsObj.SENDER_NAME || 150,
        sortable: true,
      },
      {
        Header: "수하인",
        accessor: (row) => row.receiverName,
        id: "RECEIVER_NAME",
        width: columnWidthsObj.RECEIVER_NAME || 150,
        sortable: true,
      },
      {
        Header: "지역",
        accessor: (row) =>
          `${row.receiverSidoName} ${row.receiverSigunguName}`.trim(),
        id: "REGION",
        width: columnWidthsObj.REGION || 150,
        sortable: true,
      },
      {
        Header: "도착동",
        accessor: (row) => row.receiverDongName,
        id: "RECEIVER_DONG",
        width: columnWidthsObj.RECEIVER_DONG || 90,
        sortable: true,
      },
      {
        Header: "도로명",
        accessor: (row) => row.receiverAddressRoadWithoutSigungu,
        id: "RECEIVER_ADDRESS_ROAD",
        selectable: false,
        width: columnWidthsObj.RECEIVER_ADDRESS_ROAD || 150,
        sortable: true,
      },
      {
        Header: "수거 담당",
        accessor: (row) => row.pickupRiderName,
        id: "PICKUP_RIDER_NAME",
        width: columnWidthsObj.PICKUP_RIDER_NAME || 80,
        sortable: true,
      },
      {
        Header: "배송 담당",
        accessor: (row) => row.deliveryRiderName,
        id: "DELIVERY_RIDER_NAME",
        width: columnWidthsObj.DELIVERY_RIDER_NAME || 80,
        sortable: true,
      },
    ],
    [columnWidthsObj],
  );

  return (
    <Wrap>
      <Container>
        <SearchContainer />
        <Row>
          <ButtonsCol>
            <Button onClick={handleClickAllocatePickupRider}>수거배차</Button>
            <ButtonItem onClick={handleClickAllocateDeliveryRider}>
              배송배차
            </ButtonItem>
            <Button onClick={handleClickSetPreviousStatus}>이전단계로</Button>
            <Button onClick={handleClickRestoreCancel}>취소해제</Button>
            <Button onClick={handleClickWaybill}>운송장출력</Button>
            <Button onClick={handleClickDelay}>지연사유</Button>
            {type === "download" && (
              <Button onClick={handleClickDownload}>목록다운</Button>
            )}
            {/* <Button onClick={handleClickKoomin}>배달접수</Button>
            <Button onClick={handleClickCancelKoomin}>배달접수 취소</Button> */}
          </ButtonsCol>
        </Row>
        <CheckBoxRow>
          <Col>
            <Form onChange={handleChangeCheckBoxForm}>
              <Form.Check
                id="filter-all"
                inline
                label={`전체(${state.count.all})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_ALL}
                ref={register}
              />
              <Form.Check
                id="filter-receipted"
                inline
                label={`접수(${state.count.receipted})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIPTED}
                ref={register}
              />
              <Form.Check
                id="filter-pickup-scheduled"
                inline
                label={`수거지정(${state.count.pickupScheduled})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_PICKUP_SCHEDULED}
                ref={register}
              />
              <Form.Check
                id="filter-pickup-completed"
                inline
                label={`수거완료(${state.count.pickupCompleted})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_PICKUP_COMPLETED}
                ref={register}
              />
              <Form.Check
                id="filter-warehoused"
                inline
                label={`입고(${state.count.warehoused})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_WAREHOUSED}
                ref={register}
              />
              <Form.Check
                id="filter-delivery-allocated"
                inline
                label={`배송배차(${state.count.deliveryAllocated})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_ALLOCATED}
                ref={register}
              />
              <Form.Check
                id="filter-delivery-postponed"
                inline
                label={`배송연기(${state.count.postponed})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_POSTPONED}
                ref={register}
              />
              <Form.Check
                id="filter-delivery-started"
                inline
                label={`배송출고(${state.count.deliveryStarted})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_STARTED}
                ref={register}
              />
              <Form.Check
                id="filter-delivery-completed"
                inline
                label={`배송완료(${state.count.deliveryCompleted})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_COMPLETED}
                ref={register}
              />
              <Form.Check
                id="filter-accident"
                inline
                label={`사고(${state.count.accident})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_ACCIDENT}
                ref={register}
              />
              <Form.Check
                id="filter-canceled"
                inline
                label={`취소(${state.count.canceled})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED}
                ref={register}
              />
              {state.query.types !== "1" && (
                <>
                  <Form.Check
                    id="filter-typePickup"
                    inline
                    label={`픽업(${state.count.typePickup})`}
                    name="queryTypes"
                    type="checkbox"
                    value={DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_PICKUP}
                    ref={register}
                  />
                  <Form.Check
                    id="filter-typeReturn"
                    inline
                    label={`반품(${state.count.typeReturn})`}
                    name="queryTypes"
                    type="checkbox"
                    value={DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_RETURN}
                    ref={register}
                  />
                </>
              )}
              <Form.Check
                id="filter-senderAddressError"
                inline
                label={`송하인주소오류(${state.count.senderAddressError})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR}
                ref={register}
              />
              <Form.Check
                id="filter-senderAddressErrorCanceled"
                inline
                label={`송하인주소오류취소(${state.count.senderAddressErrorCanceled})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED
                }
                ref={register}
              />
              <Form.Check
                id="filter-receiverAddressError"
                inline
                label={`수하인주소오류(${state.count.receiverAddressError})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR
                }
                ref={register}
              />
              <Form.Check
                id="filter-receiverAddressErrorCanceled"
                inline
                label={`수하인주소오류취소(${state.count.receiverAddressErrorCanceled})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED
                }
                ref={register}
              />
              <Form.Check
                id="filter-deliverySentBack"
                inline
                label={`반송완료(${state.count.deliverySentBack})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_SENT_BACK}
                ref={register}
              />
              <Form.Check
                id="filter-deliveryLost"
                inline
                label={`분실완료(${state.count.deliveryLost})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_LOST}
                ref={register}
              />
              <Form.Check
                id="filter-delayedDeliveries"
                inline
                label={`미배송기록(${state.count.delayed})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED}
                ref={register}
              />
              {/* <Form.Check
                id="filter-agencyCorpUser"
                inline
                label={`배달접수`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN}
                ref={register}
              /> */}
            </Form>
          </Col>
        </CheckBoxRow>
        <Row>
          <CounterContainer fetchTime={fetchTime} fetch={fetch} />
        </Row>
        <TableRow>
          <TableCol>
            <DeliveriesTable
              deliveries
              responsive
              bordered
              hover
              columns={columns}
              currentPage={state.query.page}
              data={state.deliveries}
              goToPage={handleGoToPage}
              nextPage={handleNextPage}
              onClickHeader={handleClickHeader}
              onSelect={handleSelect}
              pageCount={state.pageCount}
              pageSize={state.query.pageSize}
              previousPage={handlePreviousPage}
              setPageSize={handleSetPageSize}
            />
          </TableCol>
        </TableRow>
      </Container>
    </Wrap>
  );
});

export default Deliveries;
