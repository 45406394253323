import React, { memo, useCallback, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import styled from "@emotion/styled";

import RiderBox from "@/components/Allocations/RiderBox";
import { Button } from "@/components/Buttons";
import { formatDate, getDateByTimeZone } from "@/lib/date";
import {
  useAllocationsStore,
  useAuthStore,
  useLoadingStore,
  useModalStore,
  useRidersStore,
} from "@/store/hooks";
import { ALLOCATION_MATCH_TYPES, DELIVERY_STATUS_TYPES } from "@/constants";

const RiderGroup1Counts = styled.span``;

const RiderGroup1Wrap = styled.div`
  padding: 20px 0;
`;

const RiderGroup1Title = styled.h3``;

const RidersRow = styled.div`
  padding: 10px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const RefreshWrap = styled.div`
  text-align: right;
  font-size: 16px;
`;
const RefreshButton = styled(Button)`
  margin-left: 10px;
`;

const TextRed = styled.span`
  color: red;
`;

const Allocations = memo(() => {
  const { state: allocationsState, ...allocationsActions } =
    useAllocationsStore();
  const { state: authStoreState } = useAuthStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { closeModal } = useModalStore();
  const { state: ridersState, ...ridersActions } = useRidersStore();

  const [riderGroup2Id, setRiderGroup2Id] = useState("");
  const [riderGroup1Id, setRiderGroup1Id] = useState("");

  const [fetchTime, setFetchTime] = useState();

  useEffect(() => {
    allocationsActions.clear();

    fetchRiderGroup2Id();
  }, []);

  const fetchRiderGroup2Id = useCallback(async () => {
    if (
      authStoreState.user.authority === 3 &&
      authStoreState.user.rider &&
      authStoreState.user.rider.riderGroup1
    ) {
      setRiderGroup2Id(authStoreState.user.rider.riderGroup1.riderGroup2.id);
      setRiderGroup1Id(authStoreState.user.rider.riderGroup1.id);
    } else {
      const searchedRiderGroup2 = localStorage.getItem("searchedRiderGroup2");

      if (searchedRiderGroup2) {
        setRiderGroup2Id(searchedRiderGroup2);
      } else {
        ridersActions.fetchAllRiderGroup2s().then((riderGroup2s) => {
          if (riderGroup2s.length) {
            setRiderGroup2Id(riderGroup2s[0].id);
          }
        });
      }
    }
  }, [authStoreState.user, ridersActions.fetchAllRiderGroup2s]);

  useEffect(() => {
    if (riderGroup2Id) {
      fetchAll();
    }
  }, [riderGroup2Id]);

  const fetchAll = useCallback(async () => {
    startLoading();

    try {
      if (riderGroup2Id) {
        let body = {
          riderGroup2Id, // 팀
        };

        if (
          authStoreState.user.authority === 3 &&
          authStoreState.user.type === "RIDER_GROUP1_MANAGER" &&
          riderGroup1Id
        ) {
          body.riderGroup1Id = riderGroup1Id; // 조
        }

        await allocationsActions.fetchAll(body);

        setFetchTime(new Date());
      }
    } catch (e) {
      window.alert(e.message);
    }

    finishLoading();
  }, [authStoreState.user, riderGroup1Id, riderGroup2Id]);

  async function handleReallocate({
    allocationMatchType,
    bookIds,
    targetRiderId,
  } = {}) {
    try {
      await allocationsActions.reallocateInBulk({
        allocationMatchType,
        bookIds,
        targetRiderId,
      });

      window.alert(
        `총 ${bookIds.length}개의 ${
          allocationMatchType === ALLOCATION_MATCH_TYPES.DELIVERY
            ? "배송"
            : "수거"
        }건이 재배정되었습니다.`,
      );

      fetchAll();
    } catch (e) {
      window.alert(e.message);
    }

    closeModal();
  }

  const handleReallocateBulk = useCallback(
    async ({
      deliveryStatus = DELIVERY_STATUS_TYPES.DELIVERY_STARTED,
      dongId,
      fromRiderId,
      targetRiderId,
    } = {}) => {
      try {
        const response = await allocationsActions.reallocateAll({
          deliveryStatus,
          dongId,
          fromRiderId,
          targetRiderId,
        });

        window.alert(
          `총 ${response.bookIds ? response.bookIds.length : 0}개의 ${
            deliveryStatus === DELIVERY_STATUS_TYPES.DELIVERY_STARTED
              ? "배송"
              : deliveryStatus ===
                DELIVERY_STATUS_TYPES.DELIVERY_RIDER_ALLOCATED
              ? "배송대기"
              : "수거"
          }건이 재배정되었습니다.`,
        );

        fetchAll();
      } catch (e) {
        window.alert(e.message);
      }

      closeModal();
    },
    [fetchAll],
  );

  function handleSelectRiderGroup2(e) {
    setRiderGroup2Id(e.target.value);
    localStorage.setItem("searchedRiderGroup2", e.target.value);
  }

  return (
    <Container fluid>
      <Title>배차 현황</Title>

      <Form>
        <Row>
          <Col sm={4}>
            {authStoreState.user.authority !== 3 && (
              <Form.Group as={Row}>
                <Form.Label column sm={4}>
                  라이더팀 선택
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    as="select"
                    onChange={handleSelectRiderGroup2}
                    value={riderGroup2Id}
                  >
                    <option>라이더팀 선택</option>
                    {ridersState.riderGroup2s.map((rg) => (
                      <option key={rg.id} value={rg.id}>
                        {rg.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
            )}
          </Col>
          <Col>
            <RefreshWrap>
              (최근 호출 시간 :{" "}
              {formatDate(getDateByTimeZone(fetchTime), "HH:mm:ss")})
              <RefreshButton onClick={fetchAll} variant="success">
                새로고침
              </RefreshButton>
            </RefreshWrap>
          </Col>
        </Row>
      </Form>

      {Object.keys(allocationsState.allocations).map((riderGroup1Id) => (
        <RiderGroup1Wrap key={riderGroup1Id}>
          <RiderGroup1Title>
            {allocationsState.allocations[riderGroup1Id].riderGroup1.name}조
          </RiderGroup1Title>
          <RiderGroup1Counts>
            현재 - 배차예정:
            {allocationsState.allocations[riderGroup1Id].deliveryMatched}, 대기:
            {allocationsState.allocations[riderGroup1Id].deliveryWaiting},{" "}
            <TextRed>
              배출:{allocationsState.allocations[riderGroup1Id].delivery}
            </TextRed>
            , 수지:{allocationsState.allocations[riderGroup1Id].pickup}, 배완:
            {allocationsState.allocations[riderGroup1Id].deliveryCompleted},
            수완:
            {allocationsState.allocations[riderGroup1Id].pickupCompleted}
          </RiderGroup1Counts>

          <RidersRow>
            {Object.keys(
              allocationsState.allocations[riderGroup1Id].riders,
            ).map((riderId) => (
              <RiderBox
                key={riderId}
                countsByRider={
                  allocationsState.allocations[riderGroup1Id].riders[riderId]
                }
                onReallocate={handleReallocate}
                onReallocateBulk={handleReallocateBulk}
              />
            ))}
          </RidersRow>
        </RiderGroup1Wrap>
      ))}
    </Container>
  );
});

export default Allocations;
