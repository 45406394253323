export default class BaseModel {
  constructor(props) {
    Object.keys(props).forEach((key) => {
      switch (key) {
        default: {
          this[key] = props[key];
          break;
        }
      }
    });
  }
}
