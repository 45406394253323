import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import { Button } from "@/components/Buttons";
import { useAuthStore } from "@/store/hooks";

import { INDEX_PRIVATE_ROUTE_PATHNAME } from "../routes";

const LOGIN_STEP1 = "LOGIN_STEP1";
const LOGIN_STEP2 = "LOGIN_STEP2";

const Login = () => {
  const { ...actions } = useAuthStore();
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const [tryCount, setTryCount] = useState(0);

  const [step, setStep] = useState(LOGIN_STEP1);
  const [username, setUsername] = useState(null);

  const onSubmit = useCallback(
    async (data) => {
      try {
        await actions.signIn(data);
        alert("해당 메일로 인증코드를 발송했습니다.");
        setUsername(data.username);
        setStep(LOGIN_STEP2);
      } catch (e) {
        switch (e.status) {
          case 401:
            setTryCount(tryCount + 1);
            break;
          default:
            break;
        }
        alert(e.message);
      }
    },
    [tryCount, setTryCount],
  );

  const onSubmitCode = useCallback(
    async (data) => {
      try {
        const user = await actions.signInWithCode({
          username,
          code: data.code,
        });

        if (user.isKurly) {
          history.push("/deliveries-kurly");
        } else {
          history.push("/deliveries");
        }
      } catch (e) {
        alert(e.message);
      }
    },
    [username],
  );

  return (
    <Row>
      <Col>
        {step === LOGIN_STEP1 && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Label>아이디</Form.Label>
              <Form.Control
                name="username"
                placeholder="사용자 이름"
                ref={register}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>비밀번호</Form.Label>
              <Form.Control
                name="password"
                type="password"
                placeholder="비밀번호"
                ref={register}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              로그인하기
            </Button>
          </Form>
        )}
        {step === LOGIN_STEP2 && (
          <Form onSubmit={handleSubmit(onSubmitCode)}>
            <Form.Group>
              <Form.Label>인증번호</Form.Label>
              <Form.Control
                name="code"
                type="code"
                placeholder="인증번호"
                ref={register}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              인증번호 확인
            </Button>
          </Form>
        )}
      </Col>
    </Row>
  );
};

export default Login;
