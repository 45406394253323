import React, { useEffect } from "react";
import _noop from "lodash/noop";
import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";

import { DELAY_REASONS, REASON_ABSENCE, REASON_ETC } from "@/constants";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";

import { useModalStore } from "@/store/hooks";

const Title = styled.h3``;

const RadioWrap = styled.div`
  margin-bottom: 10px;
`;

const Notice = styled.p``;

export default ({ selectedDeliveries, onSubmit }) => {
  const { register, handleSubmit, reset, watch } = useForm();

  const { closeModal } = useModalStore();

  useEffect(() => {
    reset({
      reasonType: REASON_ABSENCE,
    });
  }, []);

  const handleClickClose = () => {
    closeModal();
  };

  return (
    <Modal size="md">
      <Modal.Header>
        <Title>지연 사유 등록</Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Notice>
            {selectedDeliveries[0]?.bookId} 포함 {selectedDeliveries.length}건에
            대해서 지연사유를 등록하시겠습니까?
          </Notice>
          <Form.Group>
            {DELAY_REASONS.map((_reason, idx) => {
              return (
                <RadioWrap key={idx}>
                  <Form.Check
                    id={`reasonType-${_reason.code}`}
                    inline
                    label={_reason.text}
                    name="reasonType"
                    type="radio"
                    value={_reason.code}
                    ref={register}
                  />

                  {_reason.code === REASON_ETC && (
                    <Form.Control
                      type="text"
                      name="reasonEtcText"
                      placeholder="기타 내용 입력"
                      disabled={watch("reasonType") !== REASON_ETC}
                      ref={register}
                    />
                  )}
                </RadioWrap>
              );
            })}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClickClose} variant="secondary">
            취소
          </Button>
          <Button type="submit">확인</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
