import React, { memo, useCallback, useEffect, useRef } from "react";
import _noop from "lodash/noop";
import styled from "@emotion/styled";
import JsBarcode from "react-barcode";

const PrintContainer = styled.div``;
const WaybillContainer = styled.div``;

/**
 * Style
 */
const PrintContainerStyle = {
  padding: 0,
  margin: 0,
};

const WaybillContainerStyle = {
  position: "relative",
  width: "100mm",
  height: "150mm",
  margin: 0,
  padding: 0,
};

const DefaultStyle = {
  transform: "rotate(-90deg)",
  transformOrigin: "left top",
  position: "absolute",
  fontSize: `11px`,
  whiteSpace: "pre-wrap",
  lineHeight: "3mm",
};

const Barcode = ({ bookId }) => {
  return (
    <DefaultComponent
      style={{
        top: "144mm",
        left: "70mm",
        width: "63mm",
        height: "12mm",
      }}
    >
      <JsBarcode margin={0} width={1.7} height={55} value={bookId} />
    </DefaultComponent>
  );
};

const BarcodeForSpecificCorpUser = ({ code, title = "" }) => {
  return (
    <DefaultComponent
      style={{
        top: "87mm",
        left: "3mm",
        fontSize: `10px`,
        whiteSpace: "pre-wrap",
        lineHeight: "3mm",
      }}
    >
      <div
        style={{
          top: "35mm",
          left: "22mm",
          fontSize: `10px`,
          whiteSpace: "pre-wrap",
          lineHeight: "3mm",
          textAlign: "center",
        }}
      >
        <p
          style={{
            margin: 0,
            padding: 0,
          }}
        >
          {title}
        </p>
        <JsBarcode
          fontSize="10"
          margin={0}
          width={1}
          height={40}
          value={code}
        />
      </div>
    </DefaultComponent>
  );
};

const DefaultComponent = ({ children, style = {} }) => {
  return <div style={{ ...DefaultStyle, ...style }}>{children}</div>;
};

const DongCode = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        top: `${2 + 5.5 * children.length}mm`,
        left: "15mm",
        fontSize: "20px",
        fontWeight: "bold",
        whiteSpace: "pre",
      }}
    >
      {children}
    </DefaultComponent>
  );
};

const Etc = memo(({ orderIdFromCorp, logoImage, onAfterRendered = _noop }) => {
  useEffect(() => {
    if (!logoImage) {
      setTimeout(() => {
        onAfterRendered();
      }, 200);
    }
  }, []);

  const etcStyle = {
    top: "87mm",
    left: "5mm",
    fontSize: "12px",
    whiteSpace: "pre",
  };

  if (orderIdFromCorp) {
    return (
      <DefaultComponent style={etcStyle}>
        {orderIdFromCorp.length > 20
          ? `${orderIdFromCorp.slice(0, 20)}\n${orderIdFromCorp.slice(20)}`
          : orderIdFromCorp}
      </DefaultComponent>
    );
  } else if (logoImage) {
    return (
      <DefaultComponent style={etcStyle}>
        <img
          onLoad={onAfterRendered}
          alt="기업 로고 이미지"
          src={logoImage.src}
          style={{
            width: logoImage.width,
            height: logoImage.height,
          }}
        />
      </DefaultComponent>
    );
  } else {
    return null;
  }
});

const Memo = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        top: "145mm",
        left: "65mm",
        width: "55mm",
        fontSize: "14px",
        fontWeight: "bold",
      }}
    >
      {children}
    </DefaultComponent>
  );
};

const Numbering = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        top: "20mm",
        left: "22mm",
        fontSize: `10px`,
        whiteSpace: "pre-wrap",
        lineHeight: "3mm",
      }}
    >
      {children}
    </DefaultComponent>
  );
};

const ProductNames = ({ productNames = [] }) => {
  return (
    <DefaultComponent
      style={{
        top: "85mm",
        left: "27mm",
        lineHeight: "3.5mm",
        fontSize: "12px",
        fontWeight: "bold",
        width: "70mm",
        whiteSpace: "pre",
      }}
    >
      {productNames.join("\n")}
    </DefaultComponent>
  );
};

const ProductNamesRest = ({ productNames = [] }) => {
  return (
    <DefaultComponent
      style={{
        top: "85mm",
        left: "5mm",
        lineHeight: "3.5mm",
        fontSize: "12px",
        fontWeight: "bold",
        whiteSpace: "pre-wrap",
      }}
    >
      {productNames.join("\n")}
    </DefaultComponent>
  );
};

const ProductTotalCount = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        top: "77mm",
        left: "22mm",
        fontSize: "12px",
        fontWeight: "bold",
        whiteSpace: "pre",
      }}
    >
      {children}
    </DefaultComponent>
  );
};

const ReceiptDate = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        top: "60mm",
        left: "22mm",
        fontSize: "10px",
        whiteSpace: "pre-wrap",
        lineHeight: "3mm",
      }}
    >
      {children}
    </DefaultComponent>
  );
};

const ReceiverAddress = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        top: "145mm",
        left: "28mm",
        width: "55mm",
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: "3.5mm",
      }}
    >
      {children.trim()}
    </DefaultComponent>
  );
};

const ReceiverName = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        top: "145mm",
        left: "55mm",
        fontSize: "16px",
        fontWeight: "bold",
      }}
    >
      {children}
    </DefaultComponent>
  );
};

const ReceiverMobile = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        top: "145mm",
        left: "60mm",
        fontSize: "14px",
        fontWeight: "bold",
        width: "100%",
      }}
    >
      {children}
    </DefaultComponent>
  );
};

const RestProductComponets = ({
  numOfLinesRestProductNames,
  restProductNames,
}) => {
  let productNamesComponents = [];
  for (
    let i = 0;
    i < restProductNames.length;
    i += numOfLinesRestProductNames
  ) {
    productNamesComponents.push(
      <WaybillContainer key={i} className="label" style={WaybillContainerStyle}>
        <ProductNamesRest
          productNames={restProductNames.slice(
            i,
            i + numOfLinesRestProductNames,
          )}
        />
      </WaybillContainer>,
    );
  }

  return productNamesComponents;
};

const SenderName = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        top: "145mm",
        left: "22mm",
        fontSize: "16px",
        fontWeight: "bold",
        width: "65mm",
      }}
    >
      {children}
    </DefaultComponent>
  );
};

const SenderAddress = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        top: "145mm",
        left: "15mm",
        fontSize: "11px",
        width: "65mm",
      }}
    >
      {children}
    </DefaultComponent>
  );
};

const WaybillWrap = memo(
  ({ delivery, count, totalCount, handleAfterRendered = _noop }) => {
    return (
      <>
        <WaybillContainer className="label" style={WaybillContainerStyle}>
          {/** 운송장 상단 영역 */}
          <Etc
            logoImage={delivery.displayCorpLogoImageObjBySpotCode}
            orderIdFromCorp={delivery.displayOrderIdFromCorpBySpotCode}
            onAfterRendered={handleAfterRendered}
          />

          {/** 운송장 왼쪽 영역 */}
          <SenderAddress>
            {delivery.senderAddress || delivery.senderAddressRoad || ""}
            <br />
            {delivery.displaySenderAddressDetail}
          </SenderAddress>
          <SenderName>
            {delivery.senderName} {delivery.senderMobile}
          </SenderName>
          <ReceiverAddress>
            {delivery.unrefinedAddress || delivery.customerAddress || ""}
          </ReceiverAddress>
          <ReceiverName>{delivery.receiverName} 님</ReceiverName>
          <ReceiverMobile>T: {delivery.displayReceiverMobile}</ReceiverMobile>
          <Memo>{delivery.memoFromCustomer}</Memo>
          <Barcode bookId={delivery.bookId} />

          {/** 운송장 오른쪽 영역 */}
          <DongCode>{delivery.dongCode}</DongCode>
          <ProductTotalCount>
            {delivery.renderTotalProductCount()}
          </ProductTotalCount>
          <ReceiptDate>
            접수일 {delivery.renderReceiptDate("yyyy/MM/dd HH:mm")}
          </ReceiptDate>
          <Numbering>{delivery.renderNumbering(count, totalCount)}</Numbering>
          <ProductNames productNames={delivery.firstProductNames} />
          {delivery.barcodeCodeBySpotCode && (
            <BarcodeForSpecificCorpUser
              code={delivery.barcodeCodeBySpotCode}
              title={delivery.barcodeTitleBySpotCode || null}
            />
          )}
        </WaybillContainer>

        <RestProductComponets
          numOfLinesRestProductNames={delivery.numOfLinesRestProductNames}
          restProductNames={delivery.restProductNames}
        />
      </>
    );
  },
);

export default function Print100150V2Component({
  deliveries = [],
  onLoaded = _noop,
}) {
  const loadedCount = useRef(0);

  const handleAfterRendered = useCallback(() => {
    loadedCount.current++;
    if (loadedCount.current === deliveries.length) {
      onLoaded();
    }
  }, [loadedCount.current]);

  return (
    <PrintContainer style={PrintContainerStyle}>
      {deliveries.map((d, index) => {
        return (
          <WaybillWrap
            key={index}
            delivery={d}
            count={deliveries.length - index}
            totalCount={deliveries.length}
            handleAfterRendered={handleAfterRendered}
          />
        );
      })}
    </PrintContainer>
  );
}
