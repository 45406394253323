import React, { createContext, useCallback, useReducer } from "react";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {};

const INITIAL_STATE = {};

const reducer = (state, action) => {
  switch (action.type) {
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useDelayedDeliveriesStore = () => {
  const api = useApiStore();
  // const { dispatch, state } = useContext(Context);

  const fetchLatestOneByBookId = useCallback(
    (bookId) => api.get(`/delayed-deliveries/${bookId}`),
    [api.get],
  );

  return {
    fetchLatestOneByBookId,
  };
};
