import React, { memo, useCallback, useMemo, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button, SmallButton } from "@/components/Buttons";
import Modal from "@/components/Modal";
import Table from "@/components/Table";
import { useRidersStore } from "@/store/hooks";

const RidersTable = styled(Table)``;

const Title = styled.h3`
  margin: 0;
`;

const SearchButtonColumn = styled(Col)`
  text-align: right;
`;

const SelectColumn = memo(({ rider, onSelect }) => {
  function handleClick() {
    onSelect(rider)
  }

  return <SmallButton onClick={handleClick}>선택</SmallButton>;
});

const FindRiderModal = ({ onSelect }) => {
  const { state, ...actions } = useRidersStore();
  const { register, handleSubmit, setValue } = useForm();

  const [ready, setReady] = useState(false);

  useEffect(() => {
    const searchedRiderName = localStorage.getItem("searchedRiderName");

    actions.setModalQuery({ 
      text: searchedRiderName || "",
    });

    if (searchedRiderName) {
      setValue('text', searchedRiderName);
    }

    setReady(true);
  }, []);

  useEffect(() => {
    if (ready) {
      actions.fetchAllNoDeleted();
    }
  }, [state.modalQuery.text, ready]);

  function handleGoToPage(page) {
    actions.fetchAllNoDeleted({ page, pageSize: state.pageSize });
  }

  function handleNextPage() {
    actions.fetchAllNoDeleted({ page: state.page + 1 });
  }

  function handlePreviousPage() {
    actions.fetchAllNoDeleted({ page: state.page - 1 });
  }

  function handleSetPageSize(pageSize) {
    actions.fetchAllNoDeleted({ page: 1, pageSize });
  }

  const onSubmit = useCallback(
    ({ text }) => {
      actions.setModalQuery({ text });

      localStorage.setItem("searchedRiderName", text);
    },
    [actions.setModalQuery]
  );

  const columns = useMemo(
    () => [
      {
        id: "group2",
        Header: "팀",
        accessor: (row) => row.riderGroup2Name,
        width: 50,
      },
      {
        id: "group1",
        Header: "조",
        accessor: (row) => row.riderGroup1Name,
        width: 50,
      },
      {
        Header: "이름",
        accessor: "name",
      },
      {
        Header: "연락처",
        accessor: "mobile1",
        width: 140,
      },
      {
        Header: "선택",
        accessor: (row) => <SelectColumn rider={row} onSelect={onSelect} />,
        selectable: false,
        width: 75,
      }
    ],
    []
  );

  return (
    <Modal>
      <Modal.Header>
        <Title>라이더 검색하기</Title>
      </Modal.Header>
      <Modal.Body>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={Row}>
            <Col md={6}>
              <Form.Control
                  name="text"
                  placeholder="라이더 이름"
                  ref={register}
                />
            </Col>
            <SearchButtonColumn md={6}>
              <Button type="submit">검색하기</Button>
            </SearchButtonColumn>
          </Form.Group>
        </Form>
        <RidersTable
          checkbox={false}
          responsive
          bordered
          columns={columns}
          currentPage={state.page}
          data={state.ridersNoDeleted}
          pageCount={state.pageCount}
          pageSize={state.pageSize}
          goToPage={handleGoToPage}
          nextPage={handleNextPage}
          previousPage={handlePreviousPage}
          setPageSize={handleSetPageSize}
        />
      </Modal.Body>
    </Modal>
  );
};

export default FindRiderModal;