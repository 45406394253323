import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { formatDate, getDateByTimeZone } from "@/lib/date";
import { Delivery } from "@/Models";
import { useApiStore } from "@/store/hooks";

export const ALLOCATE_TYPES = {
  DELIVERY: "DELIVERY",
  PICKUP: "PICKUP",
};

export const CONSTANTS = {
  SET_COUNT: "SET_COUNT",
  SET_QUERY: "SET_QUERY",
  FETCH_ALL: "FETCH_ALL",
  FETCH_ALL_DELAYED: "FETCH_ALL_DELAYED",
  RESET: "RESET",
  RESET_QUERY: "RESET_QUERY",
};

export const QUERY_DATE_TYPE_RECEIPT_DATE = "RECEIPT_DATE";
export const QUERY_DATE_TYPE_DELIVERY_ALLOCATION_DATE =
  "DELIVERY_ALLOCATION_DATE";
export const QUERY_DATE_TYPE_PICKUP_COMPLETED_DATE = "PICKUP_COMPLETED_DATE";
export const QUERY_DATE_TYPE_DELIVERY_COMPLETED_DATE =
  "DELIVERY_COMPLETED_DATE";

export const QUERY_DELIVERY_TYPE_ALL = "ALL";
export const QUERY_DELIVERY_TYPE_RECEIPTED = "RECEIPTED";
export const QUERY_DELIVERY_TYPE_PICKUP_SCHEDULED = "PICKUP_SCHEDULED";
export const QUERY_DELIVERY_TYPE_PICKUP_COMPLETED = "PICKUP_COMPLETED";
export const QUERY_DELIVERY_TYPE_WAREHOUSED = "WAREHOUSED";
export const QUERY_DELIVERY_TYPE_DELIVERY_ALLOCATED = "DELIVERY_ALLOCATED";
export const QUERY_DELIVERY_TYPE_DELIVERY_POSTPONED = "DELIVERY_POSTPONED";
export const QUERY_DELIVERY_TYPE_DELIVERY_STARTED = "DELIVERY_STARTED";
export const QUERY_DELIVERY_TYPE_DELIVERY_COMPLETED = "DELIVERY_COMPLETED";
export const QUERY_DELIVERY_TYPE_ACCIDENT = "ACCIDENT";
export const QUERY_DELIVERY_TYPE_CANCELED = "CANCELED";
export const QUERY_DELIVERY_TYPE_TYPE_PICKUP = "TYPE_PICKUP";
export const QUERY_DELIVERY_TYPE_TYPE_RETURN = "TYPE_RETURN";
export const QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR = "SENDER_ADDRESS_ERROR";
export const QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED =
  "SENDER_ADDRESS_ERROR_CANCELED";
export const QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR =
  "RECEIVER_ADDRESS_ERROR";
export const QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED =
  "RECEIVER_ADDRESS_ERROR_CANCELED";
export const QUERY_DELIVERY_TYPE_DELIVERY_SENT_BACK = "DELIVERY_SENT_BACK";
export const QUERY_DELIVERY_TYPE_DELIVERY_LOST = "DELIVERY_LOST";
export const QUERY_DELIVERY_TYPE_MOBILE_ERROR = "MOBILE_ERROR";
export const QUERY_DELIVERY_TYPE_DELAYED = "DELAYED";
export const QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED =
  "ADDRESS_NOT_SUPPORTED";
export const QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED =
  "ADDRESS_NOT_SUPPORTED_CANCELED";
export const QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN =
  "AGENCY_ALLOCATION_KOOKMIN"; // 배달 대행(국민배달)
export const QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_FAILED =
  "AGENCY_ALLOCATION_FAILED"; // 배달 대행 실패(국민배달)

export const AGENCY_KOOKMIN_USERNAME = "kookminfsd";
export const AGENCY_KOOKMIN_CORPTITLE = "국민fsd";

const zonedDateToday = getDateByTimeZone();
const initMonth = formatDate(zonedDateToday, "yyyy-MM");

const INITIAL_QUERY = {
  bookId: "",
  corpUserName: "",
  dateType: QUERY_DATE_TYPE_RECEIPT_DATE,
  dateFrom: null,
  dateTo: null,
  month: initMonth,
  orderBy: "",
  orderIdFromCorp: "",
  page: 1,
  pageSize: 100,
  receiverAddressRoad: "",
  receiverDong: "",
  receiverMobile: "",
  receiverName: "",
  region: "",
  rider: "",
  pickupRiderName: "",
  deliveryRiderName: "",
  senderDong: "",
  spotName: "",
  senderName: "",
  senderMobile: "",
  queryTypes: [QUERY_DELIVERY_TYPE_ALL],
  types: "1;2;3",
};

const INITIAL_STATE = {
  count: {
    all: 0,
    receipted: 0,
    pickupScheduled: 0,
    pickupCompleted: 0,
    warehoused: 0,
    deliveryAllocated: 0,
    postponed: 0,
    deliveryStarted: 0,
    deliveryCompleted: 0,
    accident: 0,
    canceled: 0,
    typePickup: 0,
    typeReturn: 0,
    senderAddressError: 0,
    senderAddressErrorCanceled: 0,
    receiverAddressError: 0,
    receiverAddressErrorCanceled: 0,
    deliverySentBack: 0,
    deliveryLost: 0,
    mobileError: 0,
    delayed: 0,
    addressNotSupported: 0,
    addressNotSupportedCanceled: 0,
  },
  deliveries: [],
  deliveriesWholeData: [],
  pageCount: 1,
  query: INITIAL_QUERY,
  reset: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        deliveriesWholeData: action.deliveriesWholeData,
        deliveries: action.deliveries,
        pageCount: action.pageCount,
      };
    case CONSTANTS.FETCH_ALL_DELAYED:
      return {
        ...state,
        deliveries: action.deliveries,
      };
    case CONSTANTS.RESET:
      return {
        ...state,
        reset: action.reset,
      };
    case CONSTANTS.RESET_QUERY:
      return {
        ...state,
        query: INITIAL_QUERY,
        reset: true,
      };
    case CONSTANTS.SET_COUNT:
      return {
        ...state,
        count: {
          ...action.count,
          delayed: action.countDelayed,
          all:
            action.count.receipted +
            action.count.pickupScheduled +
            action.count.pickupCompleted +
            action.count.warehoused +
            action.count.deliveryAllocated +
            action.count.postponed +
            action.count.deliveryStarted +
            action.count.deliveryCompleted,
        },
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

const makeQueryStringObj = (query = INITIAL_QUERY) => {
  const queryTypes =
    query.queryTypes.includes(QUERY_DELIVERY_TYPE_ALL) ||
    query.queryTypes.includes(QUERY_DELIVERY_TYPE_TYPE_PICKUP) ||
    query.queryTypes.includes(QUERY_DELIVERY_TYPE_TYPE_RETURN) ||
    query.queryTypes.includes(QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN)
      ? ""
      : query.queryTypes.join(";");

  let types = query.queryTypes.includes(QUERY_DELIVERY_TYPE_TYPE_PICKUP)
    ? "2"
    : query.queryTypes.includes(QUERY_DELIVERY_TYPE_TYPE_RETURN)
    ? "3"
    : query.types;

  let agencyCorpUserName = "";
  if (
    query.queryTypes.includes(QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN)
  ) {
    agencyCorpUserName = AGENCY_KOOKMIN_CORPTITLE;
  }

  return {
    ...query,
    queryTypes,
    types,
    agencyCorpUserName,
  };
};

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useDeliveriesMonthlyStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const countByStatus = useCallback(
    async ({ query }) => {
      const _query = {
        ...makeQueryStringObj(query),
        riderId: query.rider ? query.rider.id : "",
      };

      Promise.all([
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "receipted",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "pickupScheduled",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "pickupCompleted",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "warehoused",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "deliveryAllocated",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "deliveryStarted",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "deliveryCompleted",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "deliverySentBack",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "deliveryLost",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "accident",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "canceled",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          types: "2",
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          types: "3",
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "postponed",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "senderAddressError",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "senderAddressErrorCanceled",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "receiverAddressError",
          types: query.types,
        }),
        api.get("/deliveries/count-by-status/by-rider-group-managers", {
          ..._query,
          queryTypeForCount: "receiverAddressErrorCanceled",
          types: query.types,
        }),
        api.get("/delayed-deliveries/list/by-rider-group-managers", {
          ...makeQueryStringObj(query),
          riderId: query.rider ? query.rider.id : "",
          types: query.types,
        }),
      ]).then((results) => {
        dispatch({
          type: CONSTANTS.SET_COUNT,
          count: {
            receipted: results[0],
            pickupScheduled: results[1],
            pickupCompleted: results[2],
            warehoused: results[3],
            deliveryAllocated: results[4],
            deliveryStarted: results[5],
            deliveryCompleted: results[6],
            deliverySentBack: results[7],
            deliveryLost: results[8],
            accident: results[9],
            canceled: results[10],
            typePickup: results[11],
            typeReturn: results[12],
            postponed: results[13],
            senderAddressError: results[14],
            senderAddressErrorCanceled: results[15],
            receiverAddressError: results[16],
            receiverAddressErrorCanceled: results[17],
          },
          countDelayed: results[18].totalCount,
        });
      });

      return true;
    },
    [api.get, dispatch],
  );

  const fetchAll = useCallback(
    async ({ query }) => {
      const { deliveries, pageCount } = await api.get(
        "/deliveries/by-rider-group-managers",
        {
          ...makeQueryStringObj(query),
          riderId: query.rider ? query.rider.id : "",
        },
      );

      dispatch({
        type: CONSTANTS.FETCH_ALL,
        deliveries: deliveries.map((d) => new Delivery(d)),
        deliveriesWholeData: deliveries,
        pageCount,
      });

      return { deliveries, pageCount };
    },
    [api.get, dispatch],
  );

  const fetchAllDelayed = useCallback(
    async ({ query }) => {
      const deliveriesDelayed = await api.get(
        "/delayed-deliveries/list/by-rider-group-managers",
        {
          ...makeQueryStringObj(query),
          riderId: query.rider ? query.rider.id : "",
        },
      );

      dispatch({
        type: CONSTANTS.FETCH_ALL_DELAYED,
        deliveries: deliveriesDelayed.rows.map((d) => new Delivery(d)),
      });

      return { deliveriesDelayed };
    },
    [api.get, dispatch],
  );

  const resetQuery = useCallback(() => {
    dispatch({
      type: CONSTANTS.RESET_QUERY,
    });
  }, [dispatch]);

  const reset = useCallback(
    (reset) => {
      dispatch({
        type: CONSTANTS.RESET,
        reset,
      });
    },
    [dispatch],
  );

  const setQuery = useCallback(
    (query = {}) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch],
  );

  return {
    state,
    countByStatus,
    fetchAll,
    fetchAllDelayed,
    reset,
    resetQuery,
    setQuery,
  };
};
