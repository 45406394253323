import React, { useCallback } from "react";
import BootstrapAccordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { Button } from "@/components/Buttons";
import { EditUserPasswordModal } from "@/Modals";
import { useAuthStore, useModalStore } from "@/store/hooks";

const Accordion = styled(BootstrapAccordion)`
  &:not(:first-of-type) {
    margin-top: 10px;
  }
`;

const NavWrap = styled.nav`
  position: fixed;
  min-width: 15%;
  overflow: auto;
  height: calc(100vh - 20px);
  padding-bottom: 40px;
`;

const Li = styled.li`
  font-size: 16px;
`;

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const NavButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

const Nav = () => {
  const { user } = useAuthStore().state;
  const { ...actions } = useAuthStore();
  const { openModal } = useModalStore();

  const handleClickPassword = useCallback(() => {
    openModal(<EditUserPasswordModal key="edit-user-password-modal" />);
  }, []);

  const signout = useCallback(() => {
    actions.signOut();
  }, [actions.signOut]);

  return (
    <NavWrap>
      <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header>
            <BootstrapAccordion.Toggle
              as={styled.h5``}
              variant="link"
              eventKey="0"
            >
              배송 관리
            </BootstrapAccordion.Toggle>
          </Card.Header>
          <BootstrapAccordion.Collapse eventKey="0">
            <Card.Body>
              <Ul>
                {user.isKurly ? (
                  <Li>
                    <Link to="/deliveries-kurly">컬리 배송 목록</Link>
                  </Li>
                ) : (
                  <Li>
                    <Link to="/deliveries">배송 목록</Link>
                  </Li>
                )}
                <Li>
                  <Link to="/deliveries-monthly">월별 조회</Link>
                </Li>
                <Li>
                  <Link to="/deliveries-returns">반품 목록</Link>
                </Li>
                <Li>
                  <Link to="/deliveries-pickups">픽업 목록</Link>
                </Li>
                <Li>
                  <Link to="/deliveries-misclassification">오분류 관리</Link>
                </Li>
                <Li>
                  <Link to="/cses">CS 관리</Link>
                </Li>
              </Ul>
            </Card.Body>
          </BootstrapAccordion.Collapse>
        </Card>
      </Accordion>
      <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header>
            <BootstrapAccordion.Toggle
              as={styled.h5``}
              variant="link"
              eventKey="0"
            >
              배차 관련
            </BootstrapAccordion.Toggle>
          </Card.Header>
          <BootstrapAccordion.Collapse eventKey="0">
            <Card.Body>
              <Ul>
                <Li>
                  <Link to="/allocations">배차 현황</Link>
                </Li>
              </Ul>
            </Card.Body>
          </BootstrapAccordion.Collapse>
        </Card>
      </Accordion>
      <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header>
            <BootstrapAccordion.Toggle
              as={styled.h5``}
              variant="link"
              eventKey="0"
            >
              라이더 관련
            </BootstrapAccordion.Toggle>
          </Card.Header>
          <BootstrapAccordion.Collapse eventKey="0">
            <Card.Body>
              <Ul>
                <Li>
                  <Link to="/riders">라이더 관리</Link>
                </Li>
              </Ul>
            </Card.Body>
          </BootstrapAccordion.Collapse>
        </Card>
      </Accordion>
      <NavButton type="button" onClick={handleClickPassword}>
        비밀번호 변경
      </NavButton>
      <NavButton type="button" variant="secondary" onClick={signout}>
        로그아웃
      </NavButton>
    </NavWrap>
  );
};

export default Nav;
