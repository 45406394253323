import React, { useEffect, useRef, useState } from "react";
import diffMin from "date-fns/differenceInMinutes";
import throttle from "lodash/throttle";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { Route, Switch, useHistory, Redirect } from "react-router-dom";
import styled from "@emotion/styled";

import Alert from "@/components/Alert";
import Nav from "@/components/Nav";

import {
  useAuthStore,
  useErrorsStore,
  useLoadingStore,
  useModalStore,
} from "@/store/hooks";

import routes, { INDEX_PUBLIC_ROUTE_PATHNAME } from "./routes";

const LoadingBackdrop = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
`;

const LoadingMessage = styled.div`
  font-size: 24px;
  color: #fff;
`;

const Page = styled(Container)`
  padding-top: 20px;
`;

export default function Router() {
  const { state: authStoreState, ...authActions } = useAuthStore();
  const { state: errorsState } = useErrorsStore();
  const { state: loadingState } = useLoadingStore();
  const {
    state: { modals },
    closeModal,
  } = useModalStore();

  const history = useHistory();
  const [fromReferer] = useState(
    `${history.location.pathname}${history.location.search}` || "/",
  );

  const lastActionDateRef = useRef(new Date());
  const handleMouseMoveRef = useRef(
    throttle(() => {
      lastActionDateRef.current = new Date();
    }, 3000),
  );
  const handleLastActionCheckTimer = useRef(() => {
    const diff = diffMin(new Date(), lastActionDateRef.current);
    if (diff > 90) {
      closeModal();
      authActions.signOut();
    }
  });

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMoveRef.current);
    window.setInterval(handleLastActionCheckTimer.current, 1000 * 10);

    async function restore() {
      await authActions.restore();
    }

    if (authActions.checkAlreadySignIn()) {
      authActions.signInExistingUser();
      history.push(fromReferer);
    } else {
      try {
        restore();
        history.push(fromReferer);
      } catch (_err) {
        history.push(INDEX_PUBLIC_ROUTE_PATHNAME);
      }
    }

    return () => {
      window.clearInterval(handleLastActionCheckTimer.current);
      window.removeEventListener("mosemove", handleMouseMoveRef.current);
    };
  }, []);

  return !authStoreState.restoring ? (
    <>
      {errorsState.errors.length > 0 &&
        errorsState.errors.map((e, index) => (
          <Alert index={index} key={index} error={e} />
        ))}

      <Switch>
        {routes.map((route) => (
          <Route exact={route.exact} key={route.name} path={route.path}>
            {route.private && !authStoreState.user && (
              <Redirect to={INDEX_PUBLIC_ROUTE_PATHNAME} />
            )}

            {((route.private && authStoreState.user) || !route.private) && (
              <Page fluid={route.fluid}>
                <Row>
                  {route.nav && (
                    <Col md={2}>
                      <Nav />
                    </Col>
                  )}

                  <Col md={route.nav ? 10 : 12}>
                    <route.component />
                  </Col>
                </Row>
              </Page>
            )}
          </Route>
        ))}
      </Switch>

      {modals.map((modal) => modal)}

      {loadingState.loading && (
        <LoadingBackdrop>
          {loadingState.loadingMessage ? (
            <LoadingMessage>{loadingState.loadingMessage}</LoadingMessage>
          ) : (
            <Spinner animation="grow" />
          )}
        </LoadingBackdrop>
      )}
    </>
  ) : (
    <></>
  );
}
