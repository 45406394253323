import React from "react";
import Alert from "react-bootstrap/Alert";
import { useErrorsStore } from "@/store/hooks";

const AlertComponent = ({
  error: { humanMessage = "", message = "", status = null },
  index = -1
}) => {
  const { actions: errorsActions } = useErrorsStore();

  function handleClose() {
    errorsActions.dismiss(index);
  }

  return (
    <Alert dismissible key="alert" variant="danger" onClose={handleClose}>
      {humanMessage && <Alert.Heading>{humanMessage}</Alert.Heading>}
      {status && `${status}: `}
      {message}
    </Alert>
  );
};

export default AlertComponent;