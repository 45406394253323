import React from "react";
import OriginButton from "react-bootstrap/Button";

export const SmallButton = ({ children, variant = "info", ...restProps }) => (
  <OriginButton {...restProps} size="sm" variant={variant}>
    {children}
  </OriginButton>
);

export const Button = ({ children, variant = "info", ...restProps }) => (
  <OriginButton variant={variant} {...restProps}>
    {children}
  </OriginButton>
);
