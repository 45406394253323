import React, { memo, useCallback, useEffect } from "react";
import _noop from "lodash/noop";
import ButtonGroups from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { SmallButton, Button } from "@/components/Buttons";
import Table from "@/components/Table";

import { formatDate, getDateByTimeZone } from "@/lib/date";

import {
  CreateRiderModal,
  EditRiderModal,
  EditRiderPasswordModal,
  ImageModal,
} from "@/Modals";
import {
  useErrorsStore,
  useLoadingStore,
  useModalStore,
  useRidersStore,
} from "@/store/hooks";

const ButtonsRow = styled(Row)`
  margin-bottom: 10px;
`;

const RightButtonsGroupColumn = styled(Col)`
  text-align: right;
`;

const RidersTable = styled(Table)``;

const SearchRow = styled(Row)`
  margin-bottom: 10px;
`;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const EditColumn = ({ riderId }) => {
  const { ...ridersActions } = useRidersStore();
  const { openModal } = useModalStore();

  const handleUpdate = useCallback(() => {
    ridersActions.fetchAll();
  }, [ridersActions]);

  function handleClickEdit() {
    openModal(
      <EditRiderModal
        key="edit-rider-modal"
        riderId={riderId}
        onUpdate={handleUpdate}
      />,
    );
  }

  return <SmallButton onClick={handleClickEdit}>수정</SmallButton>;
};

const PasswordEditColumn = ({ riderId, password }) => {
  const { ...ridersActions } = useRidersStore();
  const { openModal } = useModalStore();

  const handleUpdate = useCallback(() => {
    ridersActions.fetchAll();
  }, [ridersActions]);

  function handleClickPasswordEdit() {
    openModal(
      <EditRiderPasswordModal
        key="edit-rider-password-modal"
        riderId={riderId}
        onUpdate={handleUpdate}
      />,
    );
  }

  return (
    <SmallButton onClick={handleClickPasswordEdit}>
      {password ? "수정" : "생성"}
    </SmallButton>
  );
};

const DeleteColumn = memo(({ id, onDelete = _noop }) => {
  function handleClickDelete() {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      onDelete(id);
    }
  }

  return <SmallButton onClick={handleClickDelete}>라이더 삭제</SmallButton>;
});

const SignColumn = ({ signImage, signImageDate }) => {
  const { openModal } = useModalStore();

  const handleClick = () => {
    openModal(<ImageModal key="image-modal" image={signImage} />);
  };

  if (!signImage) {
    return <div>미완료</div>;
  }

  return (
    <>
      <SmallButton onClick={handleClick}>서명보기</SmallButton>
      {signImageDate && (
        <div>
          {formatDate(getDateByTimeZone(signImageDate), "yyyyMMdd HH:mm")}
        </div>
      )}
    </>
  );
};

export default () => {
  const { actions: errorsActions } = useErrorsStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { closeModal, openModal } = useModalStore();
  const { state, ...ridersActions } = useRidersStore();
  const { handleSubmit } = useForm();

  useEffect(() => {
    ridersActions.fetchAll();
  }, []);

  const handleChange = useCallback(
    (e) => {
      ridersActions.setQuery({ [e.target.name]: e.target.value });
    },
    [ridersActions.setQuery],
  );

  const handleCreate = useCallback(() => {
    ridersActions.fetchAll();
  }, [ridersActions.fetchAll]);

  const handleClickCreate = useCallback(() => {
    openModal(
      <CreateRiderModal key="create-rider-modal" onCreate={handleCreate} />,
    );
  }, [handleCreate, openModal]);

  const handleDelete = useCallback(
    async (id) => {
      try {
        await ridersActions.deleteRider(id);
        window.alert("라이더를 삭제했습니다.");
        ridersActions.fetchAll();
      } catch (e) {
        /**
         * 현재 배차 중인 라이더라 실패했다면 한번더 확인 후 재차 삭제를 시도한다.
         */
        if (e.status === 412) {
          if (
            window.confirm(
              "기사에게 수거/배송 지정된 접수건이 있습니다. 삭제하는 경우 수거/배송이 모두 해제됩니다. 삭제하시겠습니까?",
            )
          ) {
            await ridersActions.deleteRider(id, {
              force: true,
            });
            window.alert("라이더를 삭제했습니다.");
            ridersActions.fetchAll();
          }
        } else {
          window.alert(
            `라이더 삭제에 실패하였습니다.\n실패 사유: ${e.message}`,
          );
        }
      }
    },
    [ridersActions.deleteRider, ridersActions.fetchAll],
  );

  const handleGoToPage = useCallback(
    (page) => {
      ridersActions.fetchAll({ page });
    },
    [ridersActions.fetchAll],
  );

  const handleNextPage = useCallback(() => {
    ridersActions.fetchAll({ page: state.page + 1 });
  }, [ridersActions.fetchAll, state.page]);

  const handlePreviousPage = useCallback(() => {
    ridersActions.fetchAll({ page: state.page - 1 });
  }, [ridersActions.fetchAll, state.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      ridersActions.fetchAll({ page: 1, pageSize });
    },
    [ridersActions.fetchAll],
  );

  const onSubmit = useCallback(() => {
    ridersActions.fetchAll();
  }, [ridersActions.fetchAll]);

  const columns = [
    {
      id: "group2",
      Header: "팀",
      accessor: "riderGroup2Name",
      width: 80,
    },
    {
      id: "group1",
      Header: "조",
      accessor: "riderGroup1Name",
      width: 80,
    },
    {
      Header: "라이더번호",
      accessor: "code",
      width: 100,
    },
    {
      Header: "이름",
      accessor: "name",
    },
    {
      Header: "연락처",
      accessor: "mobile1",
      width: 200,
    },
    {
      Header: "비밀번호",
      accessor: (row) => {
        return <PasswordEditColumn riderId={row.id} password={row.password} />;
      },
      selectable: false,
    },
    {
      id: "edit",
      Header: "수정",
      accessor: (row) => {
        return <EditColumn riderId={row.id} />;
      },
      selectable: false,
    },
    {
      id: "delete",
      Header: "삭제",
      accessor: (row) => <DeleteColumn id={row.id} onDelete={handleDelete} />,
      selectable: false,
    },
    {
      Header: "위탁계약서",
      accessor: (row) => (
        <SignColumn
          signImage={row.consignmentAgreement}
          signImageDate={row.consignmentAgreementDate}
        />
      ),
      selectable: false,
    },
    {
      Header: "보안서약서",
      accessor: (row) => (
        <SignColumn
          signImage={row.personalInformationSecurityPledge}
          signImageDate={row.personalInformationSecurityPledgeDate}
        />
      ),
      selectable: false,
    },
    {
      Header: "개인정보제공동의서",
      accessor: (row) => (
        <SignColumn
          signImage={row.personalInformationConsentForm}
          signImageDate={row.personalInformationConsentFormDate}
        />
      ),
      selectable: false,
    },
  ];

  return (
    <Container>
      <Row>
        <Col>
          <Title>라이더 관리</Title>
        </Col>
      </Row>
      <ButtonsRow>
        <Col>
          <ButtonGroups>
            <Button onClick={handleClickCreate}>라이더 등록</Button>
          </ButtonGroups>
        </Col>
      </ButtonsRow>
      <SearchRow>
        <Col>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={8}>
                <Form.Group>
                  <Form.Control
                    name="text"
                    onChange={handleChange}
                    placeholder="ID, 번호, 이름, 연락처, 팀/조명 조회"
                    value={state.query.text}
                  />
                </Form.Group>
              </Col>
              <Col md={{ span: 2 }}>
                <Button type="submit">검색하기</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </SearchRow>
      <Row>
        <Col>
          <RidersTable
            responsive
            bordered
            columns={columns}
            currentPage={state.page}
            data={state.riders}
            goToPage={handleGoToPage}
            nextPage={handleNextPage}
            pageCount={state.pageCount}
            pageSize={state.pageSize}
            previousPage={handlePreviousPage}
            setPageSize={handleSetPageSize}
          />
        </Col>
      </Row>
    </Container>
  );
};
