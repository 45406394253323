import BaseModel from "./BaseModel";

export class User extends BaseModel {
  /* eslint-disable-next-line */
  constructor(props) {
    super(props);
  }

  get isKurly() {
    return (
      this.username === "team@chainlogis.com" ||
      this.username === "johnj@chainlogis.co.kr" ||
      this.username === "kimjinsoo1129@naver.com" ||
      this.username === "kjaein77@fsdcorp.kr" ||
      this.username === "pch@fsdcorp.kr" ||
      this.username === "hair_red@naver.com"
    );
  }
}
