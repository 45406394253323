import React from "react";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import { Global, css } from "@emotion/react";

import {
  AllocationsStore,
  ApiStore,
  AuthStore,
  CsesStore,
  DelayedDeliveriesStore,
  DeliveriesPickupsStore,
  DeliveriesReturnsStore,
  DeliveriesStore,
  DeliveriesMonthlyStore,
  DeliveriesKurlyStore,
  DeliveriesMisclassificationStore,
  ErrorStore,
  LoadingStore,
  ModalStore,
  RiderGroupsStore,
  RidersStore,
} from "./store";

import "bootstrap/dist/css/bootstrap.min.css";

const GlobalStyle = css`
  body {
    font-size: 14px;
  }

  *,
  :after,
  :before {
    box-sizing: border-box;
  }

  .col-form-label {
    font-size: 16px;
  }
`;

const AppProvider = ({ contexts, children }) =>
  contexts.reduce(
    (prev, context) =>
      React.createElement(context, {
        children: prev,
      }),
    children,
  );

function App() {
  return (
    <>
      <Global styles={GlobalStyle} />
      <CookiesProvider>
        <AppProvider
          contexts={[
            AllocationsStore.Provider,
            ApiStore.Provider,
            AuthStore.Provider,
            CsesStore.Provider,
            DelayedDeliveriesStore.Provider,
            DeliveriesPickupsStore.Provider,
            DeliveriesReturnsStore.Provider,
            DeliveriesStore.Provider,
            DeliveriesMonthlyStore.Provider,
            DeliveriesKurlyStore.Provider,
            DeliveriesMisclassificationStore.Provider,
            ErrorStore.Provider,
            LoadingStore.Provider,
            ModalStore.Provider,
            RiderGroupsStore.Provider,
            RidersStore.Provider,
          ]}
        >
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </AppProvider>
      </CookiesProvider>
    </>
  );
}

export default App;
