import React, { createContext, useReducer } from "react";
import { useContext } from "react";

export const CONSTANTS = {
  API_ERROR: "API_ERROR",
  CLEAR: "CLEAR",
  DISMISS: "DISMISS",
};

const INITIAL_STATE = {
  errors: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.API_ERROR:
      return {
        ...state,
        errors: [
          ...state.errors,
          {
            humanMessage: action.humanMessage,
            message: action.message,
            status: action.status,
          },
        ],
      };
    case CONSTANTS.CLEAR:
      return INITIAL_STATE;
    case CONSTANTS.DISMISS:
      return {
        ...state,
        errors: [
          ...state.errors.slice(0, action.index),
          ...state.errors.slice(action.index + 1),
        ],
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const actions = {
    apiError: ({ humanMessage = "", status = null, message = "" } = {}) => {
      dispatch({
        type: CONSTANTS.API_ERROR,
        humanMessage,
        message,
        status,
      });
    },
    clear: () => {
      dispatch({
        type: CONSTANTS.CLEAR,
      });
    },
    dismiss: (index) => {
      dispatch({
        type: CONSTANTS.DISMISS,
        index,
      });
    },
  };

  return (
    <Context.Provider value={{ state, actions }}>{children}</Context.Provider>
  );
};

export const useErrorsStore = () => {
  return useContext(Context);
};
