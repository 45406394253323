import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";

import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import PrintContent from "@/components/Print/PrintContent";
import PrintPortal from "@/components/Print/PrintPortal";

import { DeliveryForWaybill } from "@/Models/DeliveryForWaybill";

import { useModalStore } from "@/store/hooks";

import {
  SIZE_TYPE_150_V3,
  SIZE_TYPE_150_V2,
  SIZE_TYPE_200,
} from "@/utils/Print";

const Title = styled.h3`
  margin: 0;
`;

const FormLabel = styled(Form.Label)`
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
`;

const ButtonWrap = styled.div`
  margin-top: 40px;
  text-align: right;
`;
const ModalButton = styled(Button)`
  margin-left: 5px;
`;

export default ({ deliveries }) => {
  const { closeModal } = useModalStore();

  const { getValues, handleSubmit, register, reset } = useForm();

  const [showPrintWindow, setShowPrintWindow] = useState(false);

  useEffect(() => {
    reset({
      sizeType: SIZE_TYPE_150_V3,
    });
  }, []);

  const onSubmit = useCallback(() => {
    setShowPrintWindow(true);
  }, []);

  return (
    <Modal size="md">
      <Modal.Header>
        <Title>운송장 출력 ({deliveries?.length}건)</Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <FormLabel>사이즈 선택</FormLabel>
            <Form.Check
              label="100 x 150 사이즈 (신규)"
              type="radio"
              id="sizeType-150-v3"
              value={SIZE_TYPE_150_V3}
              name="sizeType"
              ref={register}
            />
            <Form.Check
              label="100 x 150 사이즈"
              type="radio"
              id="sizeType-150-v2"
              value={SIZE_TYPE_150_V2}
              name="sizeType"
              ref={register}
            />
            <Form.Check
              label="100 x 200 사이즈"
              type="radio"
              id="sizeType-200"
              value={SIZE_TYPE_200}
              name="sizeType"
              ref={register}
            />
          </Form.Group>
          <ButtonWrap>
            <ModalButton type="submit" width="120px">
              출력
            </ModalButton>
            <ModalButton
              onClick={closeModal}
              type="button"
              variant="outline-secondary"
              width="120px"
            >
              취소
            </ModalButton>
          </ButtonWrap>
        </Form>

        {showPrintWindow && (
          <PrintPortal sizeType={getValues().sizeType}>
            <PrintContent
              deliveries={deliveries.map(
                (d) => new DeliveryForWaybill(d, getValues().sizeType),
              )}
              sizeType={getValues().sizeType}
            />
          </PrintPortal>
        )}
      </Modal.Body>
    </Modal>
  );
};
