import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import dfSubDays from "date-fns/subDays";
import { formatDate, getDateByTimeZone } from "@/lib/date";
import { Delivery } from "@/Models";
import { useApiStore } from "@/store/hooks";

import {
  QUERY_DATE_TYPE_RECEIPT_DATE,
  QUERY_DELIVERY_TYPE_ALL,
} from "@/store/Deliveries";

export const CONSTANTS = {
  SET_QUERY: "SET_QUERY",
  FETCH_ALL: "FETCH_ALL",
};

const zonedDate10day = dfSubDays(getDateByTimeZone(), 10);
const zonedDateToday = getDateByTimeZone();
const initDateFrom = formatDate(zonedDate10day);
const initDateTo = formatDate(zonedDateToday);

const INITIAL_QUERY = {
  bookId: "",
  dateType: QUERY_DATE_TYPE_RECEIPT_DATE,
  dateFrom: initDateFrom,
  dateTo: initDateTo,
  page: 1,
  pageSize: 50,
  types: "3",
  queryType: QUERY_DELIVERY_TYPE_ALL,
  queryTypes: [QUERY_DELIVERY_TYPE_ALL],
};

const INITIAL_STATE = {
  deliveriesReturns: [],
  pageCount: 1,
  query: INITIAL_QUERY,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        deliveriesReturns: action.deliveriesReturns,
        pageCount: action.pageCount,
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

const makeQueryStringObj = (query = INITIAL_QUERY) => {
  const queryTypes = query.queryTypes.includes(QUERY_DELIVERY_TYPE_ALL)
    ? ""
    : query.queryTypes.join(";");

  return {
    ...query,
    queryTypes,
  };
};

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useDeliveriesReturnsStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const fetchAll = useCallback(async () => {
    const { deliveries, pageCount } = await api.get(
      "/deliveries/by-rider-group-managers",
      {
        ...makeQueryStringObj(state.query),
      },
    );

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      deliveriesReturns: deliveries.map((d) => new Delivery(d)),
      pageCount,
    });

    return { deliveries, pageCount };
  }, [api.get, state.query, dispatch]);

  const setQuery = useCallback(
    (query = {}) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch],
  );

  return {
    state,
    fetchAll,
    setQuery,
  };
};
