import React, { memo, useCallback, useRef } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";

import styled from "@emotion/styled";

import { SmallButton } from "@/components/Buttons";
import { FindRidersModal, SelectDeliveriesModal } from "@/Modals";
import { useDeliveriesStore, useModalStore } from "@/store/hooks";
import { ALLOCATION_MATCH_TYPES, DELIVERY_STATUS_TYPES } from "@/constants";

const RiderCounts = styled.span``;
const RiderTitle = styled.h4``;

const TextRed = styled.span`
  color: red;
`;

const DongRow = memo(
  ({ counts, dongId, onReallocate, onReallocateBulk, fromRider }) => {
    const { fetchAllToReallocateOnTheFly } = useDeliveriesStore();
    const { closeModal, openModal } = useModalStore();
    const selectedDeliveriesRef = useRef(null);

    // 배송 재배정 단건 목록 팝업
    const handleClickReallocationDeliveries = useCallback(async () => {
      const columns = [
        {
          Header: "접수번호",
          accessor: "bookId",
        },
        {
          id: "corpUserName",
          Header: "업체명",
          accessor: (row) =>
            `${row.senderName}(${row.corpUser ? row.corpUser.corpTitle : ""})`,
        },
        {
          Header: "주소",
          accessor: "receiverAddress",
        },
        {
          Header: "상세주소",
          accessor: "receiverAddressDetail",
        },
      ];

      const deliveries = await fetchAllToReallocateOnTheFly({
        deliveryStatus: DELIVERY_STATUS_TYPES.DELIVERY_STARTED,
        dongId,
        riderId: fromRider.id,
      });

      openModal(
        <SelectDeliveriesModal
          columns={columns}
          deliveries={deliveries}
          key={`select-deliveries-modal-by-${fromRider.id}`}
          multiple
          onSelect={handleSelectDeliveryForDeliveryReallocation}
        />,
      );
    }, [fromRider]);

    // 배송 대기 재배정 단건 목록 팝업
    const handleClickDeliveriesRiderAllocated = useCallback(async () => {
      const columns = [
        {
          Header: "접수번호",
          accessor: "bookId",
        },
        {
          id: "corpUserName",
          Header: "업체명",
          accessor: (row) =>
            `${row.senderName}(${row.corpUser ? row.corpUser.corpTitle : ""})`,
        },
        {
          Header: "주소",
          accessor: "receiverAddress",
        },
        {
          Header: "상세주소",
          accessor: "receiverAddressDetail",
        },
      ];

      const deliveries = await fetchAllToReallocateOnTheFly({
        deliveryStatus: DELIVERY_STATUS_TYPES.DELIVERY_RIDER_ALLOCATED,
        dongId,
        riderId: fromRider.id,
      });

      openModal(
        <SelectDeliveriesModal
          columns={columns}
          deliveries={deliveries}
          key={`select-deliveries-modal-by-${fromRider.id}`}
          multiple
          onSelect={handleSelectDeliveryForDeliveryRiderAllocatedReallocation}
        />,
      );
    }, [fromRider]);

    // 수거 재배정 단건 목록 팝업
    const handleClickReallocationPickups = useCallback(async () => {
      const columns = [
        {
          Header: "접수번호",
          accessor: "bookId",
        },
        {
          id: "corpUserName",
          Header: "업체명",
          accessor: (row) =>
            `${row.senderName}(${row.corpUser ? row.corpUser.corpTitle : ""})`,
        },
        {
          Header: "주소",
          accessor: "senderAddress",
        },
        {
          Header: "상세주소",
          accessor: "senderAddressDetail",
        },
      ];

      const deliveries = await fetchAllToReallocateOnTheFly({
        deliveryStatus: DELIVERY_STATUS_TYPES.PICKUP_SCHEDULED,
        dongId,
        riderId: fromRider.id,
      });

      openModal(
        <SelectDeliveriesModal
          columns={columns}
          deliveries={deliveries}
          key={`select-deliveries-modal-by-${fromRider.id}`}
          multiple
          onSelect={handleSelectDeliveryForPickupReallocation}
        />,
      );
    }, [fromRider]);

    // 배송 재배정 단건 라이더 목록 팝업
    const handleSelectDeliveryForDeliveryReallocation = useCallback(
      (deliveries) => {
        selectedDeliveriesRef.current = deliveries;
        closeModal();

        openModal(
          <FindRidersModal
            key={`find-riders-modal-by-${fromRider.id}`}
            onSelect={handleSelectRiderForDeliveryReallocation}
          />,
        );
      },
      [fromRider],
    );

    // 배송 대기 재배정 단건 라이더 목록 팝업
    const handleSelectDeliveryForDeliveryRiderAllocatedReallocation =
      useCallback(
        (deliveries) => {
          selectedDeliveriesRef.current = deliveries;
          closeModal();

          openModal(
            <FindRidersModal
              key={`find-riders-modal-by-${fromRider.id}`}
              onSelect={handleSelectRiderForDeliveryReallocation}
            />,
          );
        },
        [fromRider],
      );

    // 수거 재배정 단건 라이더 목록 팝업
    const handleSelectDeliveryForPickupReallocation = useCallback(
      (deliveries) => {
        selectedDeliveriesRef.current = deliveries;
        closeModal();

        openModal(
          <FindRidersModal
            key={`find-riders-modal-by-${fromRider.id}`}
            onSelect={handleSelectRiderForPickupReallocation}
          />,
        );
      },
      [fromRider],
    );

    // 배송 재배정 일괄 라이더 목록 팝업
    const handleClickBulkReallocationDeliveries = useCallback(() => {
      openModal(
        <FindRidersModal
          key={`find-riders-modal-by-${fromRider.id}`}
          onSelect={handleSelectDeliveryRiderBulk}
        />,
      );
    }, [fromRider]);

    // 배송 대기 재배정 일괄 라이더 목록 팝업
    const handleClickBulkReallocationDeliveriesWaiting = useCallback(() => {
      openModal(
        <FindRidersModal
          key={`find-riders-modal-by-${fromRider.id}`}
          onSelect={handleSelectDeliveryWaitingRiderBulk}
        />,
      );
    }, [fromRider]);

    // 수거 재배정 일괄 라이더 목록 팝업
    const handleClickBulkReallocationPickups = useCallback(() => {
      openModal(
        <FindRidersModal
          key={`find-riders-modal-by-${fromRider.id}`}
          onSelect={handleSelectPickupRiderBulk}
        />,
      );
    }, [fromRider]);

    // 배송 재배정 일괄 처리
    const handleSelectDeliveryRiderBulk = useCallback(
      (rider) => {
        onReallocateBulk({
          deliveryStatus: DELIVERY_STATUS_TYPES.DELIVERY_STARTED,
          dongId,
          fromRiderId: fromRider.id,
          targetRiderId: rider.id,
        });
      },
      [onReallocateBulk],
    );

    // 배송 대기 재배정 일괄 처리
    const handleSelectDeliveryWaitingRiderBulk = useCallback(
      (rider) => {
        onReallocateBulk({
          deliveryStatus: DELIVERY_STATUS_TYPES.DELIVERY_RIDER_ALLOCATED,
          dongId,
          fromRiderId: fromRider.id,
          targetRiderId: rider.id,
        });
      },
      [onReallocateBulk],
    );

    // 수거 재배정 일괄 처리
    const handleSelectPickupRiderBulk = useCallback(
      (rider) => {
        onReallocateBulk({
          deliveryStatus: DELIVERY_STATUS_TYPES.PICKUP_SCHEDULED,
          dongId,
          fromRiderId: fromRider.id,
          targetRiderId: rider.id,
        });
      },
      [onReallocateBulk],
    );

    // 배송 & 배송 대기 재배정 단건 처리
    const handleSelectRiderForDeliveryReallocation = useCallback(
      (rider) => {
        onReallocate({
          allocationMatchType: ALLOCATION_MATCH_TYPES.DELIVERY,
          bookIds: selectedDeliveriesRef.current.map((d) => d.bookId),
          targetRiderId: rider.id,
        });

        selectedDeliveriesRef.current = null;
        closeModal();
      },
      [onReallocate],
    );

    // 수거 재배정 단건 처리
    const handleSelectRiderForPickupReallocation = useCallback(
      async (rider) => {
        onReallocate({
          allocationMatchType: ALLOCATION_MATCH_TYPES.PICKUP,
          bookIds: selectedDeliveriesRef.current.map((d) => d.bookId),
          targetRiderId: rider.id,
        });

        selectedDeliveriesRef.current = null;
        closeModal();
      },
      [onReallocate],
    );

    return (
      <tr>
        <td>{counts.dong.name}</td>
        <td>
          {counts.deliveryMatched} / {counts.deliveryWaiting} /{" "}
          <TextRed>{counts.delivery}</TextRed> / {counts.pickup}
        </td>
        <td>
          <ButtonGroup>
            <SmallButton onClick={handleClickReallocationDeliveries}>
              단건
            </SmallButton>
            <SmallButton
              onClick={handleClickBulkReallocationDeliveries}
              variant="info"
            >
              일괄
            </SmallButton>
          </ButtonGroup>
        </td>
        <td>
          <ButtonGroup>
            <SmallButton onClick={handleClickDeliveriesRiderAllocated}>
              단건
            </SmallButton>
            <SmallButton
              onClick={handleClickBulkReallocationDeliveriesWaiting}
              variant="info"
            >
              일괄
            </SmallButton>
          </ButtonGroup>
        </td>
        <td>
          <ButtonGroup>
            <SmallButton onClick={handleClickReallocationPickups}>
              단건
            </SmallButton>
            <SmallButton
              onClick={handleClickBulkReallocationPickups}
              variant="info"
            >
              일괄
            </SmallButton>
          </ButtonGroup>
        </td>
      </tr>
    );
  },
);

export default memo(({ countsByRider, onReallocate, onReallocateBulk }) => {
  return (
    <Card>
      <Card.Header>
        <RiderTitle>{countsByRider.rider.name}</RiderTitle>
        <RiderCounts>
          현재: {countsByRider.deliveryMatched} /{" "}
          {countsByRider.deliveryWaiting} /{" "}
          <TextRed>{countsByRider.delivery}</TextRed> / {countsByRider.pickup},
          완료:
          {countsByRider.deliveryCompleted} / {countsByRider.pickupCompleted}
        </RiderCounts>
      </Card.Header>
      <Card.Body>
        <Table>
          <thead>
            <tr>
              <th>동명</th>
              <th>
                현황(예정/대기/<TextRed>배송</TextRed>/수거)
              </th>
              <th>배송재배정</th>
              <th>배송대기재배정</th>
              <th>수거재배정</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(countsByRider.dongs).map((dongId) => (
              <DongRow
                counts={countsByRider.dongs[dongId]}
                dongId={dongId}
                fromRider={countsByRider.rider}
                key={dongId}
                onReallocate={onReallocate}
                onReallocateBulk={onReallocateBulk}
              />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
});
