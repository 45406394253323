import React, { useState } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import Table from "@/components/Table";

const DeliveriesTable = styled(Table)`
  th:nth-of-type(2) {
    width: 100px;
  }
`;

const DeliveriesTableRow = styled(Row)`
  padding: 10px 0;
`;

const Title = styled.h3``;

const SelectedDeliveriesTitle = styled.h5``;
const SelectedDeliveries = styled.p``;

const SelectDeliveriesModal = ({
  deliveries,
  columns,
  multiple = false,
  onHide = _noop,
  onSelect = _noop,
}) => {
  const [selectedDeliveries, setSelectedDeliveries] = useState([]);

  function handleSelect() {
    if (!selectedDeliveries.length) {
      window.alert("1건 이상을 선택해주세요");
    } else if (!multiple && selectedDeliveries.length > 1) {
      window.alert("1건의 배송만 선택해주세요.");
    } else if (!multiple) {
      onSelect(selectedDeliveries[0]);
    } else {
      onSelect(selectedDeliveries);
    }
  }

  function handleSelectDelivery({ selectedRows }) {
    setSelectedDeliveries(selectedRows);
  }

  return (
    <Modal onHide={onHide} size="lg">
      <Modal.Header>
        <Title>배송 선택하기</Title>
      </Modal.Header>
      <Modal.Body>
        <DeliveriesTableRow>
          <Col>
            <DeliveriesTable
              columns={columns}
              data={deliveries}
              onSelect={handleSelectDelivery}
              pagination={false}
            />
          </Col>
        </DeliveriesTableRow>
        <Row>
          <Col>
            <SelectedDeliveriesTitle>선택된 배송 목록</SelectedDeliveriesTitle>
            <SelectedDeliveries>
              {selectedDeliveries.map((d) => d.bookId).join(", ")}
            </SelectedDeliveries>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSelect}>선택하기</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectDeliveriesModal;