import React, { memo, useMemo, useCallback, useEffect } from "react";
import _noop from "lodash/noop";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { SmallButton, Button } from "@/components/Buttons";
import Table from "@/components/Table";
import { DeliveryForRiderModal } from "@/Modals";
import { DeliveriesStore } from "@/store";
import {
  useDeliveriesStore,
  useDeliveriesPickupsStore,
  useModalStore,
  useLoadingStore,
} from "@/store/hooks";

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const StyledRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`;

const StyledCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

const SearchRow = styled.div`
  margin-bottom: 10px;
`;

const ButtonWrap = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const ClickableColumn = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const DatePickerCol = styled(StyledCol)`
  display: flex;
  flex-direction: row;

  input {
    width: calc(50% - 15px);
  }
`;

const Wave = styled.span`
  margin: 0 5px;
`;

const BookIdColumn = ({ bookId, type = "1" }) => {
  const { openModal } = useModalStore();

  const onAfterUpdate = () => {};

  function handleClick() {
    openModal(
      <DeliveryForRiderModal
        backdrop
        key="book-detail-modal"
        bookId={bookId}
        onAfterUpdate={onAfterUpdate}
        type={type}
      />,
    );
  }

  return <ClickableColumn onClick={handleClick}>{bookId}</ClickableColumn>;
};

const QueryTypeButton = ({ title, queryType }) => {
  const { state, ...actions } = useDeliveriesPickupsStore();

  const handleChangeType = () => {
    let queryTypes = [];

    if (queryType === DeliveriesStore.QUERY_DELIVERY_TYPE_PICKUP_SCHEDULED) {
      queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIPTED,
        DeliveriesStore.QUERY_DELIVERY_TYPE_PICKUP_SCHEDULED,
      ];
    } else {
      queryTypes = [queryType];
    }

    actions.setQuery({ queryType, queryTypes });
  };

  return (
    <StyledButton
      onClick={handleChangeType}
      variant={state.query.queryType === queryType ? "info" : "light"}
    >
      {title}
    </StyledButton>
  );
};

const RestoreCancelColumn = memo(({ bookId, onAfterUpdate = _noop }) => {
  const { ...actions } = useDeliveriesStore();

  const handleClickDelete = useCallback(async () => {
    if (window.confirm("취소 해제 하시겠습니까?")) {
      try {
        await actions.restoreCancel([bookId]);

        onAfterUpdate();
      } catch (e) {
        window.alert(`취소 해제에 실패했습니다: ${e.message}`);
      }
    }
  }, [bookId]);

  return (
    <SmallButton onClick={handleClickDelete} variant="secondary">
      취소해제
    </SmallButton>
  );
});

export default () => {
  const { state, ...actions } = useDeliveriesPickupsStore();
  const { finishLoading, startLoading } = useLoadingStore();

  const { handleSubmit, register, reset } = useForm();

  useEffect(() => {
    reset({
      ...state.query,
    });
  }, []);

  useEffect(() => {
    fetchAll();
  }, [
    state.query.page,
    state.query.pageSize,
    state.query.dateFrom,
    state.query.dateTo,
    state.query.bookId,
    state.query.queryTypes,
  ]);

  const fetchAll = useCallback(async () => {
    try {
      startLoading();

      await actions.fetchAll();
    } catch (e) {
      window.alert(
        `픽업 목록을 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`,
      );
    }

    finishLoading();
  }, [actions.fetchAll, state.query]);

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({ page });
    },
    [actions.setQuery],
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery],
  );

  let _columns = [
    {
      Header: "예약번호",
      accessor: (row) => (
        <BookIdColumn bookId={row.bookId} type={row.type} status={row.status} />
      ),
      selectable: false,
      width: 180,
    },
    {
      Header: "접수일시",
      accessor: (row) => row.renderReceiptDate(),
      width: 180,
    },
    {
      Header: "접수점",
      accessor: (row) => row.spotName,
      width: 150,
    },
    {
      Header: "지역그룹",
      accessor: (row) =>
        `${row.senderAllocationGroupNameOnWeekdays}(주중)/${row.senderAllocationGroupNameOnWeekends}(주말)`,
      width: 200,
    },
    {
      Header: "수거 담당",
      accessor: (row) => row.pickupRiderName,
      width: 180,
    },
  ];

  if (state.query.queryType === DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED) {
    _columns.push({
      Header: "취소해제",
      accessor: (row) => (
        <RestoreCancelColumn bookId={row.bookId} onAfterUpdate={fetchAll} />
      ),
      width: 180,
    });
  }

  const columns = useMemo(() => _columns, [state.query.queryType]);

  const onSubmit = useCallback(
    ({ bookId, dateFrom, dateTo }) => {
      actions.setQuery({ bookId, dateFrom, dateTo });
    },
    [actions.setQuery],
  );

  return (
    <Container fluid>
      <Title>픽업 관리</Title>
      <SearchRow>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <StyledRow>
            <DatePickerCol md={6}>
              <Form.Control name="dateFrom" type="date" ref={register} />
              <Wave>~</Wave>
              <Form.Control name="dateTo" type="date" ref={register} />
            </DatePickerCol>
            <StyledCol md={2}>
              <Form.Control
                name="bookId"
                placeholder="배송번호"
                ref={register}
              />
            </StyledCol>
            <StyledCol md={4}>
              <Button type="submit">조회</Button>
            </StyledCol>
          </StyledRow>
        </Form>
      </SearchRow>
      <SearchRow>
        <ButtonWrap>
          <QueryTypeButton
            title="전체"
            queryType={DeliveriesStore.QUERY_DELIVERY_TYPE_ALL}
          />
          <QueryTypeButton
            title="미수거"
            queryType={DeliveriesStore.QUERY_DELIVERY_TYPE_PICKUP_SCHEDULED}
          />
          <QueryTypeButton
            title="수거완료"
            queryType={DeliveriesStore.QUERY_DELIVERY_TYPE_PICKUP_COMPLETED}
          />
          <QueryTypeButton
            title="배송연기"
            queryType={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_POSTPONED}
          />
          <QueryTypeButton
            title="배송출발"
            queryType={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_STARTED}
          />
          <QueryTypeButton
            title="배송완료"
            queryType={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_COMPLETED}
          />
          <QueryTypeButton
            title="취소"
            queryType={DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED}
          />
        </ButtonWrap>
      </SearchRow>

      <Table
        checkbox={false}
        responsive
        bordered
        columns={columns}
        currentPage={state.query.page}
        data={state.deliveriesPickups}
        goToPage={handleGoToPage}
        nextPage={handleNextPage}
        pageCount={state.pageCount}
        previousPage={handlePreviousPage}
        pageSize={state.query.pageSize}
        setPageSize={handleSetPageSize}
      />
    </Container>
  );
};
