import React from "react";
import _noop from "lodash/noop";

import styled from "@emotion/styled";

import Modal from "@/components/Modal";

const Title = styled.h3`
  margin: 0;
`;

const Text = styled.div`
  padding: 10px;
  white-space: pre-wrap;
`;

export default ({ title, text }) => {
  return (
    <Modal size="md">
      <Modal.Header>
        <Title>{title}</Title>
      </Modal.Header>
      <Modal.Body>
        <Text>{text}</Text>
      </Modal.Body>
    </Modal>
  );
};
