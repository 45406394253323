import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import _remove from "lodash/remove";
import { Cs } from "@/Models";
import { useApiStore } from "@/store/hooks";

export const TYPE_CHAINLOGIS = "CHAINLOGIS";
export const TYPE_USER = "USER";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
  SET_QUERY: "SET_QUERY",
};

const INITIAL_STATE = {
  cses: [],
  pageCount: 1,
  query: {
    tab: TYPE_USER,
    page: 1,
    pageSize: 20,
    checked: null,
    spotName: "",
    bookId: "",
    deliveryRiderName: "",
    deleted: false,
    orderBy: null,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        cses: action.cses,
        pageCount: action.pageCount,
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useCsesStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const fetchAll = useCallback(async () => {
    let query = {
      page: state.query.page,
      pageSize: state.query.pageSize,
      spotName: state.query.spotName,
      bookId: state.query.bookId,
      deliveryRiderName: state.query.deliveryRiderName,
      deleted: state.query.deleted,
      fromAdmin: state.query.tab === TYPE_CHAINLOGIS,
    };

    if (state.query.orderBy) {
      query.orderBy = state.query.orderBy;
    }
    if (typeof state.query.checked === "boolean") {
      query.checked = state.query.checked;
    }

    const { cses, totalCount } = await api.get(
      "/cses/by-rider-group-managers",
      query,
    );

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      cses: cses.map((c) => new Cs(c)),
      pageCount:
        Math.floor(totalCount / state.query.pageSize) +
        (totalCount % state.query.pageSize ? 1 : 0),
    });
  }, [api.get, dispatch, state.query]);

  const setQuery = useCallback(
    (query = {}) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch],
  );

  const update = useCallback(
    (id, data) => {
      return api.put(`/cses/${id}`, data);
    },
    [api.put],
  );

  const check = useCallback(
    (id) => {
      return api.put(`/cses/${id}/check`, {
        checked: true,
      });
    },
    [api.put],
  );

  const revertCheck = useCallback(
    (id) => {
      return api.put(`/cses/${id}/check`, {
        checked: false,
      });
    },
    [api.put],
  );

  const deleteCs = useCallback(
    (id) => {
      return api.del(`/cses/${id}`);
    },
    [api.del],
  );

  return {
    state,
    fetchAll,
    setQuery,
    update,
    check,
    revertCheck,
    deleteCs,
  };
};
